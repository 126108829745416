import { BASE_PATH } from "../config"

export function usernameToProfilePicUrl(
	username: string,
	withoutCache: boolean = true
) {
	return `${BASE_PATH}/image/profile-pic/${username}${
		withoutCache ? `?random=${Math.random()}` : ""
	}`
}

export function standProductToImageUrl(username: string, product: any) {
	return `${BASE_PATH}/image/stand/${username}/product/${encodeURIComponent(
		product.name
	)}`
}

export function usernameToStandHeroImageUrl(username: string) {
	return `${BASE_PATH}/image/stand/${username}/hero-image`
}
