import "./styles/admin.scss"

import React from "react"

import { history } from "../../App"
import Icon from "../../components/icon/Icon"
import Navbar from "../../components/navbar/Navbar"

export default function AdminContainer() {
	return (
		<div className="admin">
			<Navbar
				onBack={() => {
					history.push("/")
				}}
				title={"Admin Panel"}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"whiteboard",
					"add-remove-co-host"
				]}
			/>

			<div className="options-wrapper">
				<div className="options">
					<div
						className="option-container"
						onClick={() => history.push("/admin/create-conference")}
					>
						<Icon type="account-multiple-plus" />
						<span>Create conference</span>
					</div>

					<div
						className="option-container"
						onClick={() => history.push("/admin/manage-conference")}
					>
						<Icon type="television-classic" />
						<span>Manage conference</span>
					</div>

					<div
						className="option-container"
						onClick={() => history.push("/admin/manage-users")}
					>
						<Icon type="account-multiple-check" />
						<span>Manage users</span>
					</div>

					<div
						className="option-container"
						onClick={() => history.push("/admin/manage-rooms")}
					>
						<Icon type="account-group" />
						<span>Manage rooms</span>
					</div>
				</div>
			</div>
		</div>
	)
}
