import { Button, Card, Col, Form, Input, Row } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import Icon from "../../components/icon/Icon"
import ContentLoader from "../../components/loader/ContentLoader"
import { AppContext } from "../../context/AppContext"
import { fetchUserInfo } from "../../remote/http"
import {
	standProductToImageUrl,
	usernameToStandHeroImageUrl
} from "../../remote/images"
import { isNullOrEmpty } from "../../utils/string"
import "../auth/styles/login.scss"
import "./styles/stand.scss"

export interface Stand {
	title: string
	heroBackground: string
	stand3dLink: string
	description: string
	videoLink: string
	phone: string
	website: string
	location: string
	products: Array<any>
	sponsors: Array<any>
}

interface UserInfo {
	email: string
	stand: Stand
}

export default function StandContainer() {
	const [appContext] = useContext(AppContext)

	const params: any = useParams()

	const username = params.username

	const [userInfo, setUserInfo] = useState<UserInfo | null>(null)

	const [contactEmail, setContactEmail] = useState<string | null>()
	const [contactMessage, setContactMessage] = useState<string | null>()

	const fetchData = () => {
		fetchUserInfo(appContext.token!, username, true)
			.then((userInfo: any) => {
				if (userInfo.stand == null) {
					alert("User is not a seller")
					history.goBack()
				} else {
					setUserInfo(userInfo)
				}
			})
			.catch(() => {
				alert("Something went wrong")
				history.goBack()
			})
	}

	useEffect(() => fetchData(), [username])

	const heroStyle = {
		background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${usernameToStandHeroImageUrl(
			username
		)})`
	}

	const contactUsSubline = () =>
		userInfo?.stand?.website && userInfo?.stand?.phone ? (
			<h5>
				Visit our{" "}
				<a href={userInfo.stand.website} target="_blank">
					website
				</a>{" "}
				or call our <a href={`tel:${userInfo.stand.phone}`}>phone number</a>.
			</h5>
		) : userInfo?.stand?.website ? (
			<h5>
				Visit our{" "}
				<a href={userInfo.stand.website} target="_blank">
					website
				</a>
				.
			</h5>
		) : userInfo?.stand?.phone ? (
			<h5>
				Call our <a href={`tel:${userInfo.stand.phone}`}>phone number</a>.
			</h5>
		) : null

	return !userInfo ? (
		<ContentLoader />
	) : (
		<>
			<div className="stand">
				<div className="back hoverable" onClick={() => history.goBack()}>
					<span>
						<Icon type="arrow-left" /> Back
					</span>
				</div>

				<div className="hero" style={heroStyle}>
					{userInfo?.stand?.title && <h1>{userInfo.stand.title}</h1>}
					{userInfo?.stand?.stand3dLink && (
						<Button
							onClick={() =>
								window.open(
									userInfo.stand.stand3dLink.startsWith("http")
										? userInfo.stand.stand3dLink
										: `http://${userInfo.stand.stand3dLink}`,
									"_blank"
								)
							}
						>
							<Icon type="cube-scan" /> Visit 3D Stand
						</Button>
					)}
					<Button
						style={{ marginTop: 16 }}
						onClick={() => history.push(`/agenda/${username}`)}
					>
						<Icon type="calendar" /> View Agenda
					</Button>
				</div>

				{userInfo?.stand?.description && (
					<p className="description">{userInfo.stand.description}</p>
				)}

				{userInfo?.stand?.videoLink && (
					<iframe
						className="video"
						src={userInfo?.stand?.videoLink}
						frameBorder="0"
						allow="autoplay; fullscreen; microphone; camera; display-capture"
						allowFullScreen
					/>
				)}

				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="products">
					{userInfo?.stand?.products?.map((product: any) => (
						<Col
							className="product"
							lg={{ span: 8 }}
							md={{ span: 12 }}
							xs={{ span: 24 }}
						>
							<Card
								className="gutter-row"
								style={{ width: "100%", marginTop: 32 }}
								cover={<img src={standProductToImageUrl(username, product)} />}
							>
								<Card.Meta
									title={product.name}
									description={
										<>
											{product.salePrice ? (
												<span>
													<del>{product.price}</del> {product.salePrice}
												</span>
											) : (
												product.price
											)}
											{product.destinationUrl && (
												<Button
													style={{ marginTop: 16 }}
													className="fullwidth"
													onClick={() =>
														window.open(
															product.destinationUrl.startsWith("http")
																? product.destinationUrl
																: `http://${product.destinationUrl}`,
															"_blank"
														)
													}
												>
													<span>
														<Icon type="open-in-new" /> View
													</span>
												</Button>
											)}
										</>
									}
								/>
							</Card>
						</Col>
					))}
				</Row>

				<h2>Contact us</h2>
				{contactUsSubline()}

				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="contact">
					{!isNullOrEmpty(userInfo?.stand?.location) && (
						<Col md={{ span: 12 }} xs={{ span: 24 }}>
							<div className="maps-wrapper">
								<iframe
									className="gutter-row"
									width="100%"
									height="100%"
									scrolling="no"
									frameBorder="0"
									marginHeight={0}
									marginWidth={0}
									src={`https://maps.google.com/maps?q=${encodeURIComponent(
										userInfo?.stand?.location || ""
									)}+(${encodeURIComponent(
										userInfo?.stand?.title || ""
									)})&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
								/>
							</div>
						</Col>
					)}

					<Col
						md={{ span: !isNullOrEmpty(userInfo?.stand?.location) ? 12 : 24 }}
						xs={{ span: 24 }}
					>
						<Form
							className="form"
							onFinish={() => {
								if (!contactEmail || !contactMessage) {
									return alert("Fill the fields, please")
								}

								window.location.href = `mailto:${encodeURIComponent(
									userInfo?.email || ""
								)}?subject=${encodeURIComponent(
									"New request of contact"
								)}&body=${encodeURIComponent(contactMessage)}`
							}}
						>
							<Form.Item
								label="Email"
								name="email"
								rules={[
									{ required: true, message: "Please input your email!" }
								]}
							>
								<Input onChange={(e) => setContactEmail(e.target.value)} />
							</Form.Item>

							<Form.Item
								label="Message"
								name="message"
								rules={[{ required: true, message: "Please input a message!" }]}
							>
								<Input.TextArea
									rows={4}
									onChange={(e) => setContactMessage(e.target.value)}
								/>
							</Form.Item>

							<Form.Item>
								<Button className="fullwidth" type="primary" htmlType="submit">
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>

				{(userInfo?.stand?.sponsors || []).length > 0 && (
					<ul className="sponsors">
						{userInfo?.stand?.sponsors?.map((sponsor: any) => (
							<li
								className="sponsor hoverable"
								onClick={() => {
									window.open(
										sponsor.destinationUrl.startsWith("http")
											? sponsor.destinationUrl
											: `http://${sponsor.destinationUrl}`,
										"_blank"
									)
								}}
							>
								<img src={sponsor.imageUrl} />
							</li>
						))}
					</ul>
				)}

				<div style={{ height: 72 * 2 }} />
			</div>
		</>
	)
}
