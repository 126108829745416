import AgoraRTC from "agora-rtc-sdk"
import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import { usernameToProfilePicUrl } from "../../remote/images"
import {
	socketJoinPlenaryDirection,
	socketSendPlenaryDirectionEvent,
	socketUnregisterPlenaryDirection
} from "../../remote/socket"
import { Log } from "../../utils/logging"
import Icon from "../icon/Icon"
import "./styles/agora.scss"
import "./styles/ctrl-buttons.scss"

interface IProps {
	appId: string
	transcode: "live" | "rtc"
	agoraKey: string
	agoraScreenshareKey: string
	channel: string
	uuid: string
	attendeeMode?: string
	videoProfile?: string
	hosts?: Array<string>
	coHosts?: Array<string>
	isAssistanceMode?: boolean
	attendeesCount: number
	toggleChat?: () => void
	onCameraReady?: () => void
}

// Num of streams to num of columns
const LAYOUT_CONFIGURATIONS = {
	mobile: {
		"1": 1,
		"*": 2,
		screensharing: 2
	},
	desktop: {
		"1": 1,
		"2": 2,
		"3": 3,
		"4": 2,
		"5": 3,
		"6": 3,
		"*": 4,
		screensharing: 6
	}
}

// Do not restart video stream whenever an update to streamList occurs
let streamIdToDomElCache: any = {}

// Agora-related variables
let client: AgoraRTC.Client | undefined = undefined
let localStream: AgoraRTC.Stream | undefined = undefined
let shareClient: AgoraRTC.Client | undefined = undefined
let shareStream: AgoraRTC.Stream | undefined = undefined
let localStreams: Array<AgoraRTC.Stream> = []

// Warning level Agora logging
AgoraRTC.Logger.setLogLevel(2)

const SCREEN_SHARING_PROFILE = "720p_2"

const UNITY_DIR_USERNAME = "unitydir"
const SCREEN_SHARE_USERNAME = "-screenshare"

export default function AgoraComponent(props: IProps) {
	const [appContext] = useContext(AppContext)

	const windowWidth = useWindowWidth()

	const [streamList, setStreamList] = useState<Array<AgoraRTC.Stream>>([])
	const [readyState, setReadyState] = useState<boolean>(false)
	const [isCameraOn, setIsCameraOn] = useState<boolean>(true)
	const [isMicOn, setIsMicOn] = useState<boolean>(true)
	const [buttonsVisible, setButtonsVisible] = useState<boolean>(false)
	const [isThisUserSharing, setIsThisUserSharing] = useState<boolean>(false)
	const [isSharingWarningTooltipVisible, setIsSharingWarningTooltip] = useState<
		boolean
	>(false)
	const [directionStatus, setDirectionStatus] = useState<any>(null)
	const [columns, setColumns] = useState<number>(1)

	const isScreenSharingId = (id: string): boolean =>
		id.includes(SCREEN_SHARE_USERNAME) || id.includes(UNITY_DIR_USERNAME)

	const calculateNumOfColumns = (isSomeoneScreensharing: boolean): number => {
		const isMobile = windowWidth < 1200
		const key = isMobile ? "mobile" : "desktop"
		const config = LAYOUT_CONFIGURATIONS[key] as any
		const numOfCols = isSomeoneScreensharing
			? config["screensharing"]
			: config[String(normalSizeStreams.length)] || config["*"]
		return numOfCols
	}

	// Create stream
	useEffect(() => {
		Log.print("Creating stream...")

		client = AgoraRTC.createClient({
			mode: props.transcode,
			codec: "h264"
		})
		client.init(props.appId, () => {
			Log.print("Agora client initialized")

			subscribeStreamEvents()

			client?.join(
				props.agoraKey,
				props.channel,
				props.uuid,
				(uid: any) => {
					localStreams.push(uid)

					Log.print(
						`User ${uid} joined channel successfully as ${props.attendeeMode}`
					)

					if (props.attendeeMode === "audience") {
						setReadyState(true)
					} else {
						localStream = streamInit(
							uid,
							props.attendeeMode == "audience" ? "audience" : "video",
							props.videoProfile
						)

						Log.print("localStream initialized")

						props.onCameraReady && props.onCameraReady()

						localStream.init(
							() => {
								if (props.attendeeMode !== "audience") {
									if (localStream) {
										addStream(localStream)

										client?.publish(localStream, (err: any) => {
											err && Log.print("Publish local stream error", err)

											localStream && localStream.enableVideo()
										})
									}
								} else {
									console.error("Tried to add a null localStream")
								}

								setReadyState(true)
							},
							(err: any) => {
								console.error("getUserMedia failed", err)
								setReadyState(true)
							}
						)
					}
				},
				(err: any) => {
					console.error("Join failed", err)
				}
			)
		})
	}, [props.attendeeMode])

	// Join and unjoin direction
	useEffect(() => {
		if (props.attendeeMode != "plenary-host") {
			return
		}

		socketJoinPlenaryDirection(
			appContext.username!,
			appContext.token!,
			props.channel,
			(it: any) => setDirectionStatus(it)
		)

		return () => {
			socketUnregisterPlenaryDirection()
		}
	}, [])

	// Handle talker highlight
	useEffect(() => {
		const talkerInterval = setInterval(() => {
			streamList.forEach((stream: AgoraRTC.Stream) => {
				try {
					const audioLevel = stream.getAudioLevel()
					const el = document.getElementById(`ag-${stream.getId()}`)
					if (!el) {
						return
					}

					if (audioLevel && audioLevel > 0.1) {
						el.classList.add("talking-active")
					} else {
						el.classList.remove("talking-active")
					}
				} catch (e) {
					console.error("Error while checking speaker audio level", e)
				}
			})
		}, 300)

		return () => {
			clearInterval(talkerInterval)
		}
	}, [streamList])

	// Handle component unmount
	useEffect(() => {
		return () => {
			try {
				localStream?.stop && localStream.stop()
				localStream?.close && localStream.close()

				client?.unpublish && localStream && client.unpublish(localStream)
				shareClient?.unpublish &&
					shareStream &&
					shareClient.unpublish(shareStream)

				client?.leave(
					() => {
						Log.print("Client succeed to leave")
					},
					() => {
						Log.print("Client failed to leave")
					}
				)

				shareStream?.stop && shareStream.stop()
				shareStream?.close && shareStream.close()
				shareClient?.leave &&
					shareClient.leave(
						() => {
							Log.print("shareClient succeed to leave")
						},
						() => {
							Log.print("shareClient failed to leave")
						}
					)
			} catch (e) {
				Log.print("Error while unloading AgoraCanvas component", e)
			}
		}
	}, [])

	// On stream list update
	useEffect(() => {
		streamList.forEach((stream: AgoraRTC.Stream) => {
			const domElementId = `ag-${stream.getId()}`
			const domElement = document.getElementById(domElementId)
			if (!domElement) {
				return
			}

			const someoneIsScreensharing =
				filterScreenSharingStreams(streamList).length > 0

			addFullscreenClickListener(domElement)
			setColumns(calculateNumOfColumns(someoneIsScreensharing))

			if (someoneIsScreensharing) {
				document
					.querySelector(".normal-size-streams-container")
					?.classList?.add("with-screensharing")
			} else {
				document
					.querySelector(".normal-size-streams-container")
					?.classList?.remove("with-screensharing")
			}

			const key = Object.keys(streamIdToDomElCache).find(
				(k: string) => k === stream.getId()
			)

			let isUsingCachedDomEl = false
			if (key) {
				const cachedDomEl = streamIdToDomElCache[key]
				isUsingCachedDomEl = cachedDomEl === domElement
			}

			if (!isUsingCachedDomEl) {
				stream?.stop()
				stream?.play(
					domElementId,
					{ fit: isScreenSharingId(domElementId) ? "contain" : "cover" },
					(err: any) => {
						err && console.error("Error while playing Agora item", err)
					}
				)

				streamIdToDomElCache[stream.getId()] = domElement
			}
		})
	}, [streamList])

	// Responsively change the number of column depending on screen width
	useEffect(() => {
		setColumns(
			calculateNumOfColumns(filterScreenSharingStreams(streamList).length > 0)
		)
	}, [windowWidth])

	const streamInit = (
		userId: any,
		attendeeMode: any,
		videoProfile: any,
		config: any = {}
	) => {
		Log.print("Creating local stream...")

		let defaultConfig = {
			streamID: userId,
			audio: true,
			video: true,
			screen: false,
			audioProcessing: {
				DTX: true
			}
		}

		switch (attendeeMode) {
			case "audio-only":
				defaultConfig.video = false
				break
			case "audience":
				defaultConfig.video = false
				defaultConfig.audio = false
				break
			default:
			case "plenary-host":
			case "video":
				break
		}

		let stream = AgoraRTC.createStream(Object.assign({}, defaultConfig, config))

		stream.setVideoProfile(videoProfile)
		return stream
	}

	const subscribeStreamEvents = () => {
		client?.on("stream-added", (evt: any) => {
			const stream = evt.stream
			const streamId = stream.getId()

			Log.print("New stream added: " + streamId)
			Log.print("At " + new Date().toLocaleTimeString())
			Log.print("Subscribe ", stream)

			if (
				streamId !== `${appContext.username}-screenshare` &&
				streamId !== appContext.username
			) {
				Log.print(`Subscribing to remote stream "${streamId}"...`)
				client?.subscribe(stream, undefined, (err: any) => {
					Log.print("Subscribe stream failed", err)
				})
			}
		})

		client?.on("stream-removed", (evt: any) => {
			const stream = evt.stream

			Log.print("Stream removed: " + stream.getId())
			Log.print(new Date().toLocaleTimeString())
			Log.print(evt)

			removeStream(stream.getId())
		})

		client?.on("peer-leave", (evt: any) => {
			Log.print("Peer has left: " + evt.uid)
			Log.print(new Date().toLocaleTimeString())
			Log.print(evt)

			removeStream(evt.uid)
		})

		client?.on("stream-subscribed", (evt: any) => {
			const stream = evt.stream

			Log.print("Got stream-subscribed event")
			Log.print(new Date().toLocaleTimeString())
			Log.print("Subscribe remote stream successfully: " + stream.getId(), 1)

			addStream(stream, true)
		})

		client?.on("mute-audio", (evt: any) => {
			const user = document.getElementById(`ag-${evt.uid}`)
			if (!user) {
				return
			}

			if (user.childNodes.length !== 1) return
			clientMuteIndicator(evt.uid, "audio", true)
		})

		client?.on("unmute-audio", (evt: any) => {
			const user = document.getElementById(`ag-${evt.uid}`)
			if (!user) {
				return
			}

			if (user.childNodes.length !== 1) return
			clientMuteIndicator(evt.uid, "audio", false)
		})

		client?.on("mute-video", (evt: any) => {
			const user = document.getElementById(`ag-${evt.uid}`)
			if (!user) {
				return
			}

			if (user.childNodes.length !== 1) return
			clientMuteIndicator(evt.uid, "video", true)
		})

		client?.on("unmute-video", (evt: any) => {
			const user = document.getElementById(`ag-${evt.uid}`)
			if (!user) {
				return
			}

			if (user.childNodes.length !== 1) return
			clientMuteIndicator(evt.uid, "video", false)
		})
	}

	const clientMuteIndicator = (
		uid: any,
		type: "video" | "audio",
		isMuted: boolean
	) => {
		if (type == "video") {
			const mutedVideoEl = document.getElementById(`muted-video-${uid}`)
			if (
				!mutedVideoEl ||
				uid.includes(SCREEN_SHARE_USERNAME) ||
				uid.includes(UNITY_DIR_USERNAME)
			) {
				return
			}

			if (isMuted) {
				mutedVideoEl.classList.add(`muted`)
			} else {
				mutedVideoEl.classList.remove(`muted`)
			}
		} else {
			const nameLabelEl = document.getElementById(`name-label-${uid}`)
			if (
				!nameLabelEl ||
				uid.includes(SCREEN_SHARE_USERNAME) ||
				uid.includes(UNITY_DIR_USERNAME)
			) {
				return
			}

			nameLabelEl.innerHTML = `${
				isMuted ? `<i class="icon mdi mdi-microphone-off"></i> ` : ""
			}${uid}`
		}
	}

	const removeStream = (uid: any) => {
		setStreamList((currentStreamList) =>
			currentStreamList.filter((item: AgoraRTC.Stream) => {
				if (item.getId() === uid) {
					item.close()
					return false
				}

				return true
			})
		)
	}

	const addStream = (
		stream: AgoraRTC.Stream | undefined,
		push: boolean = false
	) => {
		if (!stream) {
			return
		}

		const hasRepetition = streamList.some(
			(item: AgoraRTC.Stream) => item.getId() === stream.getId()
		)
		if (hasRepetition) {
			return
		}

		clientMuteIndicator(stream.getId(), "video", !stream.hasVideo())
		clientMuteIndicator(stream.getId(), "audio", !stream.hasAudio())

		if (push) {
			setStreamList((currentStreamList) =>
				[...currentStreamList].concat([stream])
			)
		} else {
			setStreamList((currentStreamList) => [stream].concat(currentStreamList))
		}
	}

	const filterScreenSharingStreams = (
		streams: AgoraRTC.Stream[]
	): AgoraRTC.Stream[] => {
		return streams.filter((stream: AgoraRTC.Stream) => {
			const id = String(stream.getId())
			return (
				id.includes(SCREEN_SHARE_USERNAME) || id.includes(UNITY_DIR_USERNAME)
			)
		})
	}

	const handleShareScreen = (
		videoSource: any,
		audioSource: any,
		stream: AgoraRTC.Stream | undefined
	): any => {
		shareClient = AgoraRTC.createClient({ mode: "live", codec: "h264" })

		Log.print("Start sharing screen...")

		shareClient.init(props.appId, () => {
			Log.print("Screen client initialized")

			shareClient?.join(
				props.agoraScreenshareKey,
				props.channel,
				`${props.uuid}-screenshare`,
				(uid: any) => {
					localStreams.push(uid)

					Log.print(`Screen client joined channel (UID = ${uid})`)

					shareStream = AgoraRTC.createStream(
						videoSource
							? {
									streamID: uid,
									audio: false,
									video: true,
									mediaSource: "screen", // 'screen', 'application', 'window',
									videoSource,
									audioSource
							  }
							: {
									streamID: uid,
									audio: false,
									screenAudio: true,
									video: true,
									screen: true,
									audioProcessing: {
										AGC: false,
										AEC: false,
										ANS: false
									}
							  }
					)

					shareStream.setScreenProfile(SCREEN_SHARING_PROFILE)

					shareStream.init(
						() => {
							Log.print("Screen stream initialized")

							setIsThisUserSharing(true)

							if (shareStream) {
								shareClient?.publish(shareStream)
								addStream(shareStream, true)
							}
						},
						(err: any) => {
							console.error(err)

							shareStream?.stop()
							shareStream?.close()
							shareClient?.leave()

							removeStream(shareStream?.getId())
						}
					)

					shareStream.on("stopScreenSharing", (evt: any) => {
						Log.print("Received stopScreenSharing event")

						setIsThisUserSharing(false)

						shareStream?.stop()
						shareStream?.close()
						shareClient?.leave()

						localStream && localStream.enableVideo()

						clientMuteIndicator(evt.uid, "video", false)

						removeStream(shareStream?.getId())
					})
				}
			)
		})
	}

	const addFullscreenClickListener = (domElement: any) => {
		domElement?.addEventListener("dblclick", () => {
			try {
				const isInFullScreen =
					(document.fullscreenElement && document.fullscreenElement !== null) ||
					((document as any).webkitFullscreenElement &&
						(document as any).webkitFullscreenElement !== null) ||
					((document as any).mozFullScreenElement &&
						(document as any).mozFullScreenElement !== null) ||
					((document as any).msFullscreenElement &&
						(document as any).msFullscreenElement !== null)

				if (!isInFullScreen) {
					if (domElement.requestFullscreen) {
						domElement.requestFullscreen().catch(() => {})
					} else if (domElement.mozRequestFullScreen) {
						domElement.mozRequestFullScreen().catch(() => {})
					} else if (domElement.webkitRequestFullScreen) {
						domElement.webkitRequestFullScreen().catch(() => {})
					} else if (domElement.msRequestFullscreen) {
						domElement.msRequestFullscreen().catch(() => {})
					}
				} else {
					if (document.exitFullscreen) {
						document.exitFullscreen()
					} else if ((document as any).webkitExitFullscreen) {
						;(document as any).webkitExitFullscreen()
					} else if ((document as any).mozCancelFullScreen) {
						;(document as any).mozCancelFullScreen()
					} else if ((document as any).msExitFullscreen) {
						;(document as any).msExitFullscreen()
					}
				}
			} catch {
				Log.print("double click error")
			}
		})
	}

	const handleCamera = () => {
		if (localStream) {
			const newIsCameraOn = !isCameraOn

			newIsCameraOn ? localStream.unmuteVideo() : localStream.muteVideo()
			setIsCameraOn(newIsCameraOn)

			clientMuteIndicator(appContext.username!, "video", !newIsCameraOn)
		}
	}

	const handleMic = (e: any) => {
		const item = document.getElementById(`ag-${appContext.username}`)
		if (!item) {
			return
		}

		if (localStream) {
			const newIsAudioOn = !(localStream as any).isAudioOn()

			newIsAudioOn ? localStream.unmuteAudio() : localStream.muteAudio()
			setIsMicOn(newIsAudioOn)

			clientMuteIndicator(appContext.username!, "audio", !newIsAudioOn)
		}
	}

	const handleNextSlide = (e: any) => {
		socketSendPlenaryDirectionEvent(
			appContext.username!,
			appContext.token!,
			props.channel,
			"next"
		)
	}

	const handlePreviousSlide = (e: any) => {
		socketSendPlenaryDirectionEvent(
			appContext.username!,
			appContext.token!,
			props.channel,
			"prev"
		)
	}

	const stopSharing = () => {
		setIsThisUserSharing(false)

		shareStream && shareStream.close()
		shareClient && shareClient.leave()
	}

	const audioControlBtn = props.attendeeMode !== "audience" && (
		<div className="ag-btn-container">
			<span className="ag-btn-description">
				{isMicOn ? "turn mic off" : "turn mic on"}
			</span>
			<button
				onClick={handleMic}
				className="ag-btn audioControlBtn"
				title="Enable/Disable Audio"
			>
				<Icon type={isMicOn ? "microphone" : "microphone-off"} />
			</button>
		</div>
	)

	const videoControlBtn = props.attendeeMode !== "audience" && (
		<div className="ag-btn-container">
			<span className="ag-btn-description">
				{isCameraOn ? "turn camera off" : " turn camera on"}
			</span>

			<button
				onClick={handleCamera}
				className="ag-btn videoControlBtn"
				title="Enable/Disable Video"
			>
				<Icon type={isCameraOn ? "video-outline" : "video-off-outline"} />
			</button>
		</div>
	)

	const shareScreenBtn = props.attendeeMode !== "plenary-host" && (
		<div className="ag-btn-container">
			{isSharingWarningTooltipVisible && (
				<span className="ag-btn-tooltip">
					Only one screen sharing at the time
				</span>
			)}
			<span className="ag-btn-description">screen share</span>
			<button
				onClick={
					isThisUserSharing
						? () => stopSharing()
						: async () => {
								const isAlreadySharing = screenShareStreams.length > 0
								if (isAlreadySharing) {
									setIsSharingWarningTooltip(true)
									setTimeout(() => setIsSharingWarningTooltip(false), 4000)
									return
								}

								const isUsingMacOS =
									/Safari/i.test(navigator.userAgent) &&
									/Apple Computer/.test(navigator.vendor) &&
									!/Mobi|Android/i.test(navigator.userAgent)
								if (isUsingMacOS) {
									;(navigator.mediaDevices as any)
										.getDisplayMedia({
											video: {
												cursor: "always"
											}
										})
										.then((mediaStream: any) =>
											handleShareScreen(
												mediaStream.getVideoTracks()[0],
												mediaStream.getAudioTracks()[0],
												mediaStream
											)
										)
								} else {
									handleShareScreen(undefined, undefined, undefined)
								}
						  }
				}
				className="ag-btn videoControlBtn"
				title="Enable/Disable Screenshare"
			>
				<Icon type={isThisUserSharing ? "monitor-off" : "monitor"} />
			</button>
		</div>
	)

	const nextSlideBtn = props.attendeeMode == "plenary-host" && (
		<div className="ag-btn-container">
			<span className="ag-btn-description">Next Slide</span>
			<button
				onClick={handleNextSlide}
				className="ag-btn nextSlideBtn"
				title="Next Slide"
			>
				<Icon type="page-next-outline" />
			</button>
		</div>
	)

	const previousSlideBtn = props.attendeeMode === "plenary-host" && (
		<div className="ag-btn-container">
			<span className="ag-btn-description">Previous Slide</span>
			<button
				onClick={handlePreviousSlide}
				className="ag-btn previousSlideBtn"
				title="Previous Slide"
			>
				<Icon type="page-previous-outline" />
			</button>
		</div>
	)

	const seeMoreBtn = (
		<div className="ag-btn-container">
			<button
				onClick={() => setButtonsVisible(!buttonsVisible)}
				className="ag-btn videoControlBtn seeMore"
			>
				<Icon type={!buttonsVisible ? "dots-vertical" : "close"} />
			</button>
		</div>
	)

	const chatControlBtn = (
		<div className="ag-btn-container">
			<span className="ag-btn-description">open chat</span>

			<button
				onClick={props.toggleChat}
				className="ag-btn videoControlBtn seeMore"
			>
				<Icon customClass="message-icon" type="message-text-outline" />
			</button>
		</div>
	)

	const shouldShowButtons =
		props.isAssistanceMode ||
		(props.hosts && props.hosts.includes(appContext.username!)) ||
		(props.coHosts && props.coHosts.includes(appContext.username!))

	const screenShareStreams: AgoraRTC.Stream[] = filterScreenSharingStreams(
		streamList
	)

	const isScreenSharing = screenShareStreams.length > 0

	let normalSizeStreams: AgoraRTC.Stream[] = streamList.filter(
		(stream: AgoraRTC.Stream) => {
			return !screenShareStreams.includes(stream)
		}
	)
	if (isScreenSharing) {
		let usernameOfTheUserWhoIsScreensharing = screenShareStreams[0].getId()
		if (usernameOfTheUserWhoIsScreensharing) {
			usernameOfTheUserWhoIsScreensharing = (usernameOfTheUserWhoIsScreensharing as string).split(
				"-"
			)[0]

			const normalStreamsWithoutTheOneWhoIsMakingScreenshare = normalSizeStreams.filter(
				(stream: AgoraRTC.Stream) =>
					stream.getId() != usernameOfTheUserWhoIsScreensharing
			)
			const streamOfTheUserWhoIsScreensharing = normalSizeStreams.find(
				(stream: AgoraRTC.Stream) =>
					stream.getId() == usernameOfTheUserWhoIsScreensharing
			)
			if (streamOfTheUserWhoIsScreensharing) {
				normalSizeStreams = [streamOfTheUserWhoIsScreensharing].concat(
					normalStreamsWithoutTheOneWhoIsMakingScreenshare
				)
			}
		}
	}

	return (
		<div className="agora-webcall">
			<>
				{props.attendeesCount !== -1 && streamList.length > 0 && (
					<span className="attendees-count">
						<span className="red-dot" />
						LIVE <Icon type="account" /> {props.attendeesCount}
					</span>
				)}

				{streamList.length == 0 ? (
					<div id="no-streams">
						<Icon type="account-question" />
						<h3 className="title">No users yet...</h3>
					</div>
				) : (
					<>
						{screenShareStreams.length > 0 && (
							<div className="screen-share-container">
								<div id={`ag-${screenShareStreams[0].getId()}`} />
							</div>
						)}
						<div
							className="normal-size-streams-container"
							style={{
								gridTemplateColumns: `repeat(${columns}, 1fr)`
							}}
						>
							{normalSizeStreams.map(
								(stream: AgoraRTC.Stream, index: number) => (
									<div
										className="stream-container"
										key={`${stream.getId()}-${index}`}
									>
										<div className="stream" id={`ag-${stream.getId()}`} />
										<span
											className="stream-name"
											id={`name-label-${stream.getId()}`}
										>
											{stream.getId()}
										</span>
										<div
											className="profile-pic-wrapper"
											id={`muted-video-${stream.getId()}`}
										>
											<img
												src={usernameToProfilePicUrl(String(stream.getId()))}
											/>
										</div>
									</div>
								)
							)}
						</div>
					</>
				)}
				{props.attendeeMode !== "audience" && shouldShowButtons && readyState && (
					<>
						<div className="ag-btn-group">
							{directionStatus && (
								<span className="ag-option-label">{directionStatus}</span>
							)}
							{previousSlideBtn}
							{nextSlideBtn}
							{shareScreenBtn}
							{videoControlBtn}
							{audioControlBtn}
						</div>
						<div className="ag-btn-mobile">
							<div
								className="ag-btn-group-mobile"
								style={{ display: buttonsVisible ? "flex" : "none" }}
							>
								{directionStatus && (
									<span className="ag-option-label">{directionStatus}</span>
								)}
								{previousSlideBtn}
								{nextSlideBtn}
								{chatControlBtn}
								{videoControlBtn}
								{audioControlBtn}
							</div>
							{seeMoreBtn}
						</div>
					</>
				)}
			</>
		</div>
	)
}
