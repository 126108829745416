import React from 'react';

interface Props {
  type: string
  customClass?: string
  onClick?: () => void
}

export default function Icon(props: Props) {
  return <i onClick={props.onClick} className={`icon mdi mdi-${props.type} ${props.customClass || ""}`} />;
}
