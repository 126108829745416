import { Avatar, Input, List } from "antd"
import Modal from "antd/lib/modal/Modal"
import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { addRemoveCoHost, searchUsers } from "../../remote/http"
import { usernameToProfilePicUrl } from "../../remote/images"
import Icon from "../icon/Icon"
import "./styles/add-remove-co-host-modal.scss"

interface Props {
	roomHash: string
	visible: boolean
	onCancel: () => void
}

export default function AddRemoveCoHostModal(props: Props) {
	const [appContext] = useContext(AppContext)

	const [foundParticipants, setFoundParticipants] = useState<Array<any>>()
	const [isSearchLoading, setIsSearchLoading] = useState<boolean>()

	const search = (q: string) => {
		setIsSearchLoading(true)
		setFoundParticipants(undefined)

		searchUsers(appContext.token!, q, props.roomHash)
			.then((foundParticipants: any) => {
				setFoundParticipants(foundParticipants)
				setIsSearchLoading(false)
			})
			.catch(console.error)
	}

	const onAddRemoveCoHostClick = (participant: any) => {
		if (participant.isCoHost) {
			addRemoveCoHost(
				appContext.token!,
				props.roomHash,
				[],
				[participant.username]
			)
		} else {
			addRemoveCoHost(
				appContext.token!,
				props.roomHash,
				[participant.username],
				[]
			)
		}

		setFoundParticipants(
			foundParticipants?.map((fp: any) => {
				if (fp.username == participant.username) {
					fp.isCoHost = !fp.isCoHost
				}
				return fp
			})
		)
	}

	useEffect(() => {
		if (props.visible) {
			setFoundParticipants(undefined)
		}
	}, [props.visible])

	return (
		<Modal
			title={`Add / Remove Co-Hosts`}
			visible={props.visible}
			centered={true}
			closable={true}
			destroyOnClose={true}
			className="add-remove-co-host-modal modal"
			onCancel={props.onCancel}
			okButtonProps={{ style: { display: "none" } }}
		>
			<Input.Search
				placeholder="Search people"
				loading={isSearchLoading}
				onSearch={(value) => search(value)}
				style={{ width: "100%" }}
			/>
			{!foundParticipants ? (
				<h3 style={{ color: "white" }}>No users found</h3>
			) : (
				<List
					itemLayout="horizontal"
					dataSource={foundParticipants}
					renderItem={(participant: any) => (
						<List.Item onClick={() => onAddRemoveCoHostClick(participant)}>
							<List.Item.Meta
								avatar={
									<Avatar src={usernameToProfilePicUrl(participant.username)} />
								}
								title={`${participant.name} ${participant.surname}`}
								description={
									<span>
										<Icon
											type={participant.isCoHost ? "close" : "account-plus"}
										/>{" "}
										{participant.isCoHost
											? "Remove from co-hosts list"
											: "Add to co-hosts list"}
									</span>
								}
							/>
						</List.Item>
					)}
				/>
			)}
		</Modal>
	)
}
