import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import React, { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import Chat from "../../components/chat/Chat"
import EmbedLink from "../../components/embed-link/EmbedLink"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import { joinRoom } from "../../remote/http"
import {
	socketJoinRoom,
	socketUnregisterRoomUpdates
} from "../../remote/socket"
import "./styles/webinar.scss"
import { AppContext } from "../../context/AppContext"

interface JoinData {
	roomName?: string
	customizations?: any
	embedLinks?: Array<string>
	whiteboardLink?: string
}

export default () => {
	const [appContext] = useContext(AppContext)

	const params: any = useParams()

	const [joinData, setJoinData] = useState<JoinData>()

	const [isChatOpened, setIsChatOpened] = useState<boolean>(false)

	const [sourceIndex, setSourceIndex] = useState<number>(0)
	const [currentEmbedLink, setCurrentEmbedLink] = useState<string | undefined>(
		undefined
	)

	const changeVideoSource = () => {
		const newSource =
			sourceIndex + 1 == (joinData?.embedLinks?.length || 0)
				? 0
				: sourceIndex + 1

		setSourceIndex(newSource)
		setCurrentEmbedLink(
			(joinData?.embedLinks && joinData?.embedLinks[sourceIndex]) || ""
		)
	}

	const actionHandler = (type: String) => {
		switch (type) {
			case "video-source":
				changeVideoSource()
				break
			case "chat":
				setIsChatOpened(!isChatOpened)
				break
			default:
				break
		}
	}

	useEffect(() => {
		const roomHash = params.roomHash
		joinRoom(appContext.token!, roomHash)
			.then((res) => {
				setJoinData(res)
				setCurrentEmbedLink(res.embedLinks && res.embedLinks[0])
			})
			.catch((err) => {
				alert(`Error while joining the room: ${err}`)
				history.push("/")
			})

		socketJoinRoom(appContext.token!, roomHash, () => {
			Modal.confirm({
				title: "Warning",
				icon: <ExclamationCircleOutlined />,
				content:
					"This room got updated. Click the OK button below to refresh the page.",
				centered: true,
				maskClosable: false,
				okText: "OK",
				cancelText: "Cancel",
				onOk: () => window.location.reload(),
				onCancel() {}
			})
		})

		return () => {
			socketUnregisterRoomUpdates()
		}
	}, [])

	const cn = ["room", "webinar", "main", isChatOpened ? "chat-opened" : ""]
		.filter(Boolean)
		.join(" ")

	return !joinData?.customizations ? (
		<ContentLoader />
	) : (
		<div className={cn}>
			<div className="room-workspace">
				<Navbar
					onBack={() => history.push("/home")}
					title={joinData?.roomName || "Loading..."}
					background={
						joinData?.customizations?.headerBackground ||
						appContext?.accentColor
					}
					hiddenIcons={[
						"chat-conversations",
						"notifications",
						"share-room-link",
						"whiteboard",
						"help",
						"add-remove-co-host"
					].concat(
						joinData?.embedLinks && joinData?.embedLinks.length > 1
							? ""
							: "video-source"
					)}
					{...{
						videoSource: joinData?.embedLinks
							? `Channel ${sourceIndex + 1}`
							: "Loading..."
					}}
					onAction={actionHandler}
					whiteboardLink={joinData?.whiteboardLink}
				/>
				<div className="workspace fullscreen">
					{currentEmbedLink && <EmbedLink src={currentEmbedLink} />}
				</div>
			</div>
			<div className="room-chat">
				<Chat
					onClose={() => setIsChatOpened(!isChatOpened)}
					chatBackground={joinData?.customizations?.chatBackground}
					roomHash={params.roomHash}
					moderators={joinData?.customizations?.moderators}
				/>
			</div>
		</div>
	)
}
