import { Button, Card, Col, Input, Row } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { history } from "../../App"
import Icon from "../../components/icon/Icon"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { fetchListAllUsers, searchUsers } from "../../remote/http"
import { usernameToProfilePicUrl } from "../../remote/images"
import { Log } from "../../utils/logging"
import "./styles/list-users.scss"

export default function ListUsersContainer() {
	const [appContext] = useContext(AppContext)

	const [users, setUsers] = useState<Array<any> | null>(null)
	const [isRefreshButtonLoading, setIsRefreshButtonLoading] = useState<boolean>(
		false
	)
	const [
		isRefreshLoadMoreButtonLoading,
		setIsRefreshLoadMoreButtonLoading
	] = useState<boolean>(false)
	const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)

	const [currentPage, setCurrentPage] = useState<number>(-1)

	const fetchData = (page: number) => {
		const newPage = page < 1 ? 1 : page

		fetchListAllUsers(appContext.token!, newPage)
			.then((remoteUsers: any) => {
				const oldScrollY = window.scrollY

				const newUsers =
					newPage > 1 ? [...(users || []), ...remoteUsers] : remoteUsers
				setUsers(newUsers)

				setIsRefreshButtonLoading(false)
				setIsRefreshLoadMoreButtonLoading(false)

				window.scrollTo(0, oldScrollY)

				Log.print(remoteUsers)
			})
			.catch((err: any) => {
				alert(`Something went wrong - ${err}`)
				history.goBack()
			})
	}

	const search = (query: string) => {
		setIsRefreshButtonLoading(true)
		setIsSearchLoading(true)

		searchUsers(appContext.token!, query!)
			.then((newUsers: any) => {
				setUsers(newUsers)

				setIsRefreshButtonLoading(false)
				setIsRefreshLoadMoreButtonLoading(false)
				setIsSearchLoading(false)
			})
			.catch((err) => {
				alert(`Error - ${err}`)

				setIsRefreshButtonLoading(false)
				setIsRefreshLoadMoreButtonLoading(false)
				setIsSearchLoading(false)
			})
	}

	useEffect(() => {
		if (currentPage == -1) {
			return
		}

		fetchData(currentPage)
	}, [currentPage])

	return (
		<div className="list-users">
			<Navbar
				onBack={() => history.goBack()}
				background={appContext?.accentColor}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"whiteboard",
					"add-remove-co-host"
				]}
			/>

			<div className="content">
				<h1 className="title">Search users</h1>
				<Input.Search
					placeholder="Search people"
					loading={isSearchLoading}
					onSearch={(value) => search(value)}
					style={{ width: "100%" }}
				/>

				<Button
					style={{ marginTop: 32 }}
					loading={isRefreshButtonLoading}
					onClick={() => {
						if (currentPage == 0) {
							setIsRefreshButtonLoading(true)
							setTimeout(() => setIsRefreshButtonLoading(false), 1500)
							return
						}

						setIsRefreshButtonLoading(true)
						setCurrentPage(0)
					}}
				>
					{!isRefreshButtonLoading && (
						<Icon type="account-box-multiple" customClass="load-more" />
					)}
					{isRefreshButtonLoading ? "Loading..." : "Load All People"}
				</Button>
				{users && users?.length == 0 ? (
					<div className="empty-state">
						<Icon type="account-question" />
						<br />
						<span>No users</span>
					</div>
				) : (
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="users">
						{users?.map((participant: any) => (
							<Col
								className="user"
								lg={{ span: 8 }}
								md={{ span: 12 }}
								xs={{ span: 24 }}
							>
								<Card
									className="gutter-row"
									style={{ width: "100%", marginTop: 32 }}
									cover={
										<img
											style={{ height: 340, objectFit: "cover" }}
											src={usernameToProfilePicUrl(participant.username, false)}
										/>
									}
								>
									<Card.Meta
										title={`${participant.isPremium ? `⭐️ ` : ``}${
											participant.name
										} ${participant.surname}`}
										description={
											<>
												<a
													className="email hoverable"
													href={`mailto:${participant.email}`}
													target="_blank"
												>
													<Icon type="email" />
													{`${participant.email}`}
												</a>
												<br />
												{participant.participantType ? (
													<>
														<div className="participant-type">
															<Icon type="account-tie" />
															{participant.participantType}
														</div>
														<br />
													</>
												) : null}
												{participant.website ? (
													<>
														<a
															className="website hoverable"
															href={participant.website}
															target="_blank"
														>
															<Icon type="open-in-new" />
															{`${participant.website}`}
														</a>
														<br />
													</>
												) : null}
												{participant.standTitle ? (
													<>
														<div
															className="stand-title hoverable"
															onClick={() =>
																history.push(`/stand/${participant.username}`)
															}
														>
															<Icon type="store" />
															{participant.standTitle}
														</div>
														<br />
													</>
												) : null}
												<div
													className="private-chat hoverable"
													onClick={() =>
														history.push(`/chat/${participant.username}`)
													}
												>
													<Icon type="message" />
													Chat
												</div>
												<br />
												<Button
													style={{ marginTop: 16, width: 156 }}
													onClick={() =>
														history.push(`/agenda/${participant.username}`)
													}
												>
													<Icon type="calendar" /> Agenda
												</Button>
											</>
										}
									/>
								</Card>
							</Col>
						))}
					</Row>
				)}

				{users && users.length > 0 && (
					<div style={{ textAlign: "center" }}>
						<Button
							style={{ marginTop: 32, marginBottom: 72 }}
							loading={isRefreshLoadMoreButtonLoading}
							onClick={() => {
								setIsRefreshLoadMoreButtonLoading(true)
								setCurrentPage((currentPage < 1 ? 1 : currentPage) + 1)
							}}
						>
							<span>
								{!isRefreshLoadMoreButtonLoading && (
									<Icon type="chevron-down" />
								)}
								{isRefreshLoadMoreButtonLoading ? "Loading..." : "Load more"}
							</span>
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
