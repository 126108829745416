import { Avatar } from "antd"
import React, { useContext, useState } from "react"
import ago from "s-ago"
import { AppContext } from "../../context/AppContext"
import { usernameToProfilePicUrl } from "../../remote/images"
import ViewAccountModal from "../modal-account/ViewAccountModal"
import "./styles/chat-message.scss"

interface Props {
	moderators?: Array<string>
	username: string
	message?: string
	timestamp?: string
	iAmModerator: boolean
}

export default function (props: Props) {
	const [appContext] = useContext(AppContext)

	const [isAccountModalVisible, setIsAccountModalVisible] = useState<boolean>(
		false
	)

	const copyMessageToClipboard = () => {
		if (!props.iAmModerator) {
			return
		}

		navigator.clipboard.writeText(`${props.username}: ${props.message}` || "")
	}

	const rightSide = props.username == appContext.username

	const avatar = () => (
		<div
			className="avatar hoverable"
			onClick={() => setIsAccountModalVisible(true)}
		>
			<Avatar src={usernameToProfilePicUrl(props.username, false)} size={48} />
		</div>
	)

	const body = () => (
		<div
			className="chat-message__body"
			style={{
				width: "100%",
				textAlign: rightSide ? "right" : "left",
				marginRight: rightSide ? 16 : 0
			}}
		>
			<div className="author">
				{props.username}{" "}
				{(props.moderators || []).includes(props.username || "")
					? "[MOD]"
					: null}
			</div>
			<div className="message">{props.message}</div>
			{props.timestamp && (
				<div className="timestamp">{ago(new Date(props.timestamp))}</div>
			)}
		</div>
	)

	return (
		<>
			<div
				className={`chat-message ${props.iAmModerator ? "hoverable" : ""}`}
				onClick={copyMessageToClipboard}
			>
				{rightSide ? body() : avatar()}
				{rightSide ? avatar() : body()}
			</div>

			<ViewAccountModal
				visible={isAccountModalVisible}
				onCancel={() => setIsAccountModalVisible(false)}
				username={props.username}
			/>
		</>
	)
}
