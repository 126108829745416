import { BASE_PATH } from "../config"
import { Stand } from "../containers/stand/StandContainer"
import { IAppContext } from "../context/AppContext"

export function signup(credentials: any) {
	const {
		email,
		name,
		surname,
		username,
		password,
		// sellerOrBuyer,
		participantType
	} = credentials

	return fetch(`${BASE_PATH}/participant/signup`, {
		method: "POST",
		body: JSON.stringify({
			email,
			name,
			surname,
			username,
			password,
			// sellerOrBuyer,
			participantType
		}),
		headers: {
			"Content-Type": "application/json"
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve()
		})
}

export async function login(
	appContext: IAppContext,
	setAppContext: Function,
	credentials: any
) {
	const { email, password } = credentials

	const rawRes = await fetch(`${BASE_PATH}/participant/login`, {
		method: "POST",
		body: JSON.stringify({
			email,
			password
		}),
		headers: {
			"Content-Type": "application/json"
		}
	})

	const json = await rawRes.json()
	if (!json.success) {
		if (json.error == "RECORD NOT FOUND") {
			return Promise.reject("Cannot find user!")
		}

		if (json.error == "BAD AUTHENTICATION") {
			return Promise.reject("Wrong credentials!")
		}

		if (json.error == "USER NOT VERIFIED") {
			return Promise.reject("User is not verified!")
		}

		if (!json.inFoyerVisible) {
			return Promise.reject("Foyer is not visible yet!")
		}

		return Promise.reject()
	}

	if (!json.conferenceId && !json.isAdmin && !json.isSeller) {
		return Promise.reject("Conference is not ready yet!")
	}

	setAppContext({
		...appContext,

		email,
		username: json.username,
		token: json.token,
		isAdmin: json.isAdmin,
		isHelpDesk: json.isHelpDesk,
		isSeller: json.isSeller
	})

	return Promise.resolve(json)
}

export function forgotPasswordUpdate(jwt: string, values: any) {
	const { newPassword } = values

	return fetch(`${BASE_PATH}/participant/forgot-password/update`, {
		method: "POST",
		body: JSON.stringify({
			newPassword
		}),
		headers: {
			"Content-Type": "application/json",
			Authorization: jwt
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
}

export function forgotPasswordLinkRequest(values: any) {
	const { email } = values

	return fetch(`${BASE_PATH}/participant/forgot-password`, {
		method: "POST",
		body: JSON.stringify({
			email
		}),
		headers: {
			"Content-Type": "application/json"
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
}

export async function verifyUser(token: string) {
	const rawRes = await fetch(`${BASE_PATH}/participant/verify`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})

	const json = await rawRes.json()
	if (!json.success) {
		return Promise.reject(json.error)
	}

	return Promise.resolve(json)
}

export function adminCreateConference(token: string, values: any) {
	const {
		eventTitle,
		eventDescription,
		eventStartsAt,
		loginBodyBackground,
		loginCardBackground,
		loginLeftSideBackground,
		foyerBodyBackground,
		foyerHeaderBackground,
		foyerLeftSideBackground,
		foyerLogo,
		foyerVisibleAt,
		privacyPolicyUrl,
		rooms,
		users
	} = values

	return fetch(`${BASE_PATH}/admin/create-conference`, {
		method: "POST",
		body: JSON.stringify({
			eventTitle,
			eventDescription,
			eventStartsAt,
			loginBodyBackground,
			loginCardBackground,
			loginLeftSideBackground,
			foyerBodyBackground,
			foyerHeaderBackground,
			foyerLeftSideBackground,
			foyerLogo,
			foyerVisibleAt,
			privacyPolicyUrl,
			rooms,
			users
		}),
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
}

export function adminCreateRoom(token: string, values: any) {
	const {
		name,
		startsAt,
		endsAt,
		embedLinks,
		roomType,
		withoutWhiteboard,
		isPublic
	} = values

	return fetch(`${BASE_PATH}/admin/create-room`, {
		method: "POST",
		body: JSON.stringify({
			name,
			startsAt,
			endsAt,
			embedLinks,
			roomType,
			withoutWhiteboard,
			isPublic
		}),
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
}

export function adminListAllUsers(token: string) {
	return fetch(`${BASE_PATH}/admin/list-users`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
}

export function adminListRooms(token: string) {
	return fetch(`${BASE_PATH}/admin/list-rooms`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
}

export function fetchConferenceInfo(token: string) {
	return fetch(`${BASE_PATH}/admin/conference-info`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
}

export function adminUpdateUsers(token: string, usersToUpdate: any) {
	return fetch(`${BASE_PATH}/admin/update-users`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		},
		body: JSON.stringify({ usersToUpdate })
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve()
		})
}

export function adminUpdateRooms(token: string, roomsToUpdate: any) {
	return fetch(`${BASE_PATH}/admin/update-rooms`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		},
		body: JSON.stringify({ roomsToUpdate })
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve()
		})
}

export function adminUpdateConference(token: string, infoToUpdate: any) {
	return fetch(`${BASE_PATH}/admin/update-conference`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		},
		body: JSON.stringify(infoToUpdate)
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve()
		})
}

export function joinRoom(token: string, roomHash: string) {
	return fetch(`${BASE_PATH}/room/join`, {
		method: "POST",
		body: JSON.stringify({
			roomHash
		}),
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
		.catch((err) => {
			console.error(err)
			return Promise.reject(err)
		})
}

export function fetchListRoomMessages(
	token: string,
	lastMessageTimestamp: any,
	roomHash: any = null,
	username: any = null
) {
	return fetch(
		`${BASE_PATH}/chat/messages?${new URLSearchParams({
			roomHash,
			username,
			lastMessageTimestamp
		})}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: token
			}
		}
	)
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
		.catch((err) => {
			return Promise.reject(err)
		})
}

export function fetchChatConversations(token: string, page: any) {
	return fetch(
		`${BASE_PATH}/chat/conversations?${new URLSearchParams({
			page
		})}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: token
			}
		}
	)
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
		.catch((err) => {
			return Promise.reject(err)
		})
}

export function fetchUserInfo(
	token: string,
	username: string,
	asSeller: Boolean = false
) {
	const url = `${BASE_PATH}/participant/${username}?${new URLSearchParams({
		asSeller: asSeller ? "true" : "false"
	})}`
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
		.catch((err) => {
			console.error(err)
			return Promise.reject(err)
		})
}

export function fetchMyUserInfo(token: string) {
	const url = `${BASE_PATH}/participant/by-token/me`
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
		.catch((err) => {
			console.error(err)
			return Promise.reject(err)
		})
}

export function fetchReservationsOnDay(
	token: string,
	username: string,
	date: Date
) {
	const url = `${BASE_PATH}/participant/${username}/reservations/${date.toUTCString()}`
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
		.catch((err) => {
			console.error(err)
			return Promise.reject(err)
		})
}

export function fetchReservations(token: string, username: string) {
	const url = `${BASE_PATH}/participant/${username}/reservations`
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
		.catch((err) => {
			console.error(err)
			return Promise.reject(err)
		})
}

export function makeReservation(
	token: string,
	username: string,
	date: Date,
	note: string | undefined
) {
	return fetch(`${BASE_PATH}/participant/${username}/make-reservation`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		},
		body: JSON.stringify({
			username,
			date,
			note
		})
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve()
		})
		.catch((err) => {
			return Promise.reject(err)
		})
}

export function acceptReservationRequest(token: string, reservationId: string) {
	return fetch(`${BASE_PATH}/participant/accept-reservation`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		},
		body: JSON.stringify({
			reservationId
		})
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve()
		})
		.catch((err) => {
			return Promise.reject(err)
		})
}

export function updateUserInfo(
	token: string,
	values: {
		email: string | undefined | null
		name: string | undefined | null
		surname: string | undefined | null
		website: string | undefined | null
		profilePicBase64: any
		stand: Stand | undefined | null
	}
) {
	return fetch(`${BASE_PATH}/participant/update-info`, {
		method: "POST",
		body: JSON.stringify(values),
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res)
		})
		.catch((err) => {
			console.error(err)
			return Promise.reject(err)
		})
}

export function fetchLoginCustomizations() {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await fetch(`${BASE_PATH}/customizations/login`)
			const json = await res.json()
			if (!json.success) {
				return reject()
			}

			resolve(json.customizations)
		} catch (err) {
			reject(err)
		}
	})
}

export function fetchHomeContent(appContext: IAppContext, token: string) {
	return fetch(`${BASE_PATH}/conference/home`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.conference) {
				appContext.logout!()

				return Promise.reject("Conference is null!")
			}

			return Promise.resolve(res)
		})
}

export async function generateShareableRoomLink(
	token: string,
	roomHash?: string
) {
	let url = `${BASE_PATH}/room/generate-shareable-link`
	if (roomHash) {
		url += `?${new URLSearchParams({ roomHash })}`
	}
	const rawRes = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		}
	})

	const json = await rawRes.json()
	if (!json.success) {
		return Promise.reject(json.error)
	}

	return Promise.resolve(json)
}

export function getHelpDeskTickets(token: string) {
	const url = `${BASE_PATH}/helpdesk/participants`
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	}).then((res) => res.json())
}

export function createTicketRequest(token: string) {
	return fetch(`${BASE_PATH}/conference/create-helpdesk-ticket`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		}
	})
		.then((res) => res.json())
		.then((res) => {
			return Promise.resolve(res)
		})
}

export function updateHelpdeskTicket(
	token: string,
	ticketId: any,
	newStatus: string
) {
	return fetch(`${BASE_PATH}/helpdesk/update-status`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: token
		},
		body: JSON.stringify({
			ticketId,
			newStatus
		})
	}).then((res) => res.json())
}

export function fetchListAllUsers(token: string, page: number) {
	return fetch(
		`${BASE_PATH}/conference/list-users?${new URLSearchParams({
			page: page.toString()
		})}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		}
	)
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
}

export function searchUsers(
	token: string,
	query: string,
	roomHashForCoHostCheck: any = null
) {
	const params: any = {
		q: query
	}
	if (roomHashForCoHostCheck) {
		params.roomHashForCoHostCheck = roomHashForCoHostCheck
	}

	return fetch(
		`${BASE_PATH}/conference/search-users?${new URLSearchParams(params)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		}
	)
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
}

export function addRemoveCoHost(
	token: string,
	roomHash: string,
	usernamesToAdd: string[],
	usernamesToRemove: string[]
) {
	return fetch(`${BASE_PATH}/room/add-remove-co-host`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token
		},
		body: JSON.stringify({
			roomHash,
			usernamesToAdd,
			usernamesToRemove
		})
	})
		.then((res) => res.json())
		.then((res) => {
			if (!res.success) {
				return Promise.reject(res.error)
			}

			return Promise.resolve(res.data)
		})
}
