import { Checkbox, DatePicker } from "antd"
import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import message from "antd/lib/message"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { history } from "../../App"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { adminUpdateConference, fetchConferenceInfo } from "../../remote/http"
import { Log } from "../../utils/logging"
import "./styles/admin.scss"

export default function AdminManageConferenceContainer(props: any) {
	const [appContext] = useContext(AppContext)

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const [initialValues, setInitialValues] = useState<any>(null)

	const onFinish = async (values: any) => {
		setLoading(true)

		try {
			await adminUpdateConference(appContext.token!, values)

			setLoading(false)

			alert("Done!")
		} catch (e) {
			Log.print("Error!", e)

			const errorMsg = e.toString()
			message.error(errorMsg)

			setLoading(false)
		}
	}

	useEffect(() => {
		fetchConferenceInfo(appContext.token!)
			.then(setInitialValues)
			.catch((err: any) => {
				console.error("Error!", err)
				setInitialValues({})
			})
	}, [])

	useEffect(() => form.resetFields(), [initialValues])

	return !initialValues ? (
		<ContentLoader />
	) : (
		<div className="admin">
			<Navbar
				onBack={() => history.goBack()}
				title={"Admin Panel - Manage Conference"}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"add-remove-co-host"
				]}
			/>

			<div className="create-conference-form">
				<Form
					className="form"
					form={form}
					onFinish={onFinish}
					initialValues={{
						eventTitle: initialValues?.eventTitle,
						eventDescription: initialValues?.eventDescription,
						eventStartsAt: initialValues?.eventStartsAt
							? moment(initialValues?.eventStartsAt)
							: moment(0),
						loginBodyBackground:
							initialValues?.customizations?.login?.bodyBackground,
						loginCardBackground:
							initialValues?.customizations?.login?.cardBackground,
						loginLeftSideBackground:
							initialValues?.customizations?.login?.leftSideBackground,
						foyerBodyBackground:
							initialValues?.customizations?.foyer?.bodyBackground,
						foyerHeaderBackground:
							initialValues?.customizations?.foyer?.headerBackground,
						foyerLeftSideBackground:
							initialValues?.customizations?.foyer?.leftSideBackground,
						foyerLogo: initialValues?.customizations?.foyer?.logo,
						foyerVisibleAt: initialValues?.customizations?.foyer?.visibleAt
							? moment(initialValues?.customizations?.foyer?.visibleAt)
							: moment(0),
						foyerScheduleLink:
							initialValues?.customizations?.foyer?.scheduleLink,
						privacyPolicyUrl:
							initialValues?.customizations?.login?.privacyPolicyUrl,
						isPublic: initialValues?.isPublic
					}}
				>
					<Form.Item
						name="eventTitle"
						label="Event Title"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="eventDescription"
						label="Event Description"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="eventStartsAt"
						label="Event Date"
						rules={[{ required: false }]}
					>
						<DatePicker
							style={{ width: "100%", height: 48, borderRadius: 8 }}
							format="YYYY-MM-DD HH:mm:ss"
							showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
						/>
					</Form.Item>
					<Form.Item
						name="foyerVisibleAt"
						label="Foyer Visible At"
						rules={[{ required: false }]}
					>
						<DatePicker
							style={{ width: "100%", height: 48, borderRadius: 8 }}
							format="YYYY-MM-DD HH:mm:ss"
							showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
						/>
					</Form.Item>
					<Form.Item
						name="loginBodyBackground"
						label="Login Body Background (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="loginCardBackground"
						label="Login Card Background (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="loginLeftSideBackground"
						label="Login Left Side Background (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="foyerBodyBackground"
						label="Foyer Body Background (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="foyerHeaderBackground"
						label="Foyer Header Background (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="foyerLeftSideBackground"
						label="Foyer Left Side Background (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="foyerLogo"
						label="Foyer Logo (Hex / URL)"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="foyerScheduleLink"
						label="Schedule Link"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="privacyPolicyUrl"
						label="Privacy Policy URL"
						rules={[{ required: false }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="isPublic"
						label="Is Public"
						rules={[{ required: false }]}
						valuePropName="checked"
					>
						<Checkbox />
					</Form.Item>
					<Form.Item name="submit" style={{ marginTop: 32 }}>
						<Button
							type="primary"
							htmlType="submit"
							disabled={
								!!form.getFieldsError().filter(({ errors }) => errors.length)
									.length
							}
							loading={loading}
						>
							Update Conference
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}
