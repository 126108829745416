import "./styles/helpdesk-tickets-list.scss"

import React, { useEffect, useState, useContext } from "react"

import { history } from "../../App"
import Navbar from "../../components/navbar/Navbar"
import { getHelpDeskTickets, updateHelpdeskTicket } from "../../remote/http"
import {
	socketUnregisterHelpDeskUpdates,
	socketJoinHelpDesk
} from "../../remote/socket"
import { AppContext } from "../../context/AppContext"

export default () => {
	const [appContext] = useContext(AppContext)

	const [tickets, setTickets] = useState<Array<any> | null>(null)

	const closeTicket = (ticket: any) => {
		setTickets((tickets || []).filter((t: any) => t.hash !== ticket.hash))

		updateHelpdeskTicket(appContext.token!, ticket.roomName, "CLOSED")
			.then((res) => {
				if (!res.success) {
					alert(`Error: ${res}`)
				}
			})
			.catch(console.error)
	}

	const takeInChargeTicket = (ticket: any) => {
		setTickets(
			(tickets || []).map((t: any) => {
				if (ticket.roomName == t.roomName) {
					t.isTakingInCharge = true
				}
				return t
			})
		)
		updateHelpdeskTicket(appContext.token!, ticket.roomName, "ONGOING")
			.then(() => {
				history.push(`/talk-with-assistance/${ticket.hash}`)
			})
			.catch(console.error)
	}

	useEffect(() => {
		const updateTickets = () => {
			getHelpDeskTickets(appContext.token!)
				.then(setTickets)
				.catch(console.error)
		}

		updateTickets()

		socketJoinHelpDesk(appContext.token!, updateTickets)

		return () => {
			socketUnregisterHelpDeskUpdates()
		}
	}, [])

	return (
		<div className="helpdesk-ticket-list">
			<Navbar
				onBack={() => {
					history.push("/")
				}}
				title={"Help Desk Tickets"}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"whiteboard",
					"add-remove-co-host"
				]}
			/>
			<h3 style={{ margin: 42, color: "white" }}>
				The refresh of the tickets happens automatically
			</h3>
			<ul>
				{!tickets ? (
					<h2>Loading...</h2>
				) : (tickets || []).length === 0 ? (
					<h2>No tickets</h2>
				) : (
					(tickets || []).map((ticket: any) => (
						<li>
							<br />
							<b>{ticket.username}</b>
							– 
							<u className="hoverable" onClick={() => closeTicket(ticket)}>
								{ticket.isClosingTicket ? "Closing ticket..." : "Close Ticket"}
							</u>
							– 
							<u
								className="hoverable"
								onClick={() => takeInChargeTicket(ticket)}
							>
								{ticket.isTakingInCharge
									? "Taking in charge..."
									: "Take in Charge"}
							</u>
							<br />
							<span>Status: {ticket.status}</span>
							<br />
							<span>Ticket ID: {ticket.roomName}</span>
							<br />
						</li>
					))
				)}
			</ul>
		</div>
	)
}
