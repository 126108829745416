import React, { useState, useEffect, useContext } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Avatar } from 'antd'
import { fetchUserInfo } from '../../remote/http'
import { usernameToProfilePicUrl } from '../../remote/images'
import { AppContext } from '../../context/AppContext'

interface Props {
    username: string
    visible: boolean
    onCancel: () => void
}

export default function ViewAccountModal(props: Props) {
    const [appContext] = useContext(AppContext)

    const [profileData, setProfileData] = useState<any>()

    useEffect(() => {
        fetchUserInfo(appContext.token!, props.username!)
            .then(setProfileData)
            .catch(console.error)
    }, [])

    return (
        <Modal
            title={`User Profile`}
            visible={props.visible}
            centered={true}
            closable={true}
            className="modal"
            onCancel={props.onCancel}
        >
            {
                !profileData
                    ? <h3 style={{ color: "white" }}>Loading...</h3>
                    :
                    <>
                        <Avatar src={usernameToProfilePicUrl(props.username, false)} size={84} />
                        <h4 style={{ color: "white" }}>{`${profileData.name} ${profileData.surname}`}</h4>
                        <a href={`mailto:${profileData.website}`}
                            target="_blank"
                            style={{ color: "white", textDecoration: "underline", fontSize: 16 }}>
                            {profileData.email}
                        </a>
                        {profileData.website &&
                            <a href={profileData.website} target="_blank" className="hoverable">
                                {profileData.website}
                            </a>
                        }
                    </>
            }
        </Modal>
    )
}