import './styles/embed-link.scss'

import React from 'react'

interface Props {
  src: string
  onLoad?: any
}

export default function EmbedLink(props: Props) {
  return (
    <div className="monitor">
      <iframe
        src={props.src}
        onLoad={props.onLoad}
        frameBorder="0"
        allow="autoplay; fullscreen; microphone; camera; display-capture"
        allowFullScreen
      />
    </div>
  )
}
