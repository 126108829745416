export default function dateToCountdownString(
	end: Date,
	now: Date = new Date()
): string | null {
	const _second = 1000
	const _minute = _second * 60
	const _hour = _minute * 60
	const _day = _hour * 24

	const delta = end.getTime() - now.getTime()
	if (delta < 0) {
		return null
	}

	// const days = Math.floor(distance / _day);
	const hours = "" + Math.floor((delta % _day) / _hour)
	const minutes = "" + Math.floor((delta % _hour) / _minute)
	const seconds = "" + Math.floor((delta % _minute) / _second)

	return `${addZeroIfNeeded(hours)}:${addZeroIfNeeded(
		minutes
	)}:${addZeroIfNeeded(seconds)}`
}

export const addZeroIfNeeded = (s: any) => {
	if (typeof s !== "string") {
		s = "" + s
	}
	if (s.length == 1) return `0${s}`
	else return s
}
