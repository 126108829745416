import { Checkbox, Select, Modal } from "antd"
import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import { Option } from "antd/lib/mentions"
import message from "antd/lib/message"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { history } from "../../App"
import ContentLoader from "../../components/loader/ContentLoader"
import { fetchLoginCustomizations, signup } from "../../remote/http"
import { isNullOrEmpty } from "../../utils/string"
import "./styles/register.scss"

export default function RegisterContainer() {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [customizations, setCustomizations] = useState<any | null>(null)

	useEffect(() => {
		fetchLoginCustomizations()
			.then(setCustomizations)
			.catch((err) => {
				setCustomizations({})
			})
	}, [])

	const onFinish = async (values: any) => {
		setLoading(true)

		try {
			await signup(values)

			Modal.success({
				title: "Done!",
				content: "Please check your inbox to confirm your email address.",
				onOk: () => history.push("/")
			})
		} catch (e) {
			message.error(`Error while sign up: ${e}`)
			setLoading(false)
		}
	}

	const registerBackgroundStyle = !isNullOrEmpty(customizations?.bodyBackground)
		? {
				background: customizations.bodyBackground.startsWith("http")
					? `url(${customizations.bodyBackground})`
					: customizations.bodyBackground
		  }
		: {}
	const registerCardStyle = !isNullOrEmpty(customizations?.cardBackground)
		? {
				background: customizations.cardBackground.startsWith("http")
					? `url(${customizations.cardBackground})`
					: customizations.cardBackground
		  }
		: {}

	return !customizations ? (
		<ContentLoader />
	) : (
		<>
			<div
				className="register-background-layer"
				style={registerBackgroundStyle}
			/>
			<div className="register-wrapper">
				<div className="auth register" style={registerCardStyle}>
					<div className="register-form">
						<Form className="form" onFinish={onFinish}>
							<h2>Register</h2>

							<Form.Item
								name="email"
								label="Email"
								rules={[
									{ required: true, message: "Please input your email!" },
									{
										type: "email",
										message: "The input is not valid E-mail!"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="username"
								label="Username"
								rules={[
									{ required: true, message: "Please input your username!" },
									{
										type: "string",
										message: "The input is not valid username!"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="name"
								label="Name"
								rules={[
									{ required: true, message: "Please input your name!" },
									{
										type: "string",
										message: "The input is not valid name!"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="surname"
								label="Surname"
								rules={[
									{ required: true, message: "Please input your surname!" },
									{
										type: "string",
										message: "The input is not valid surname!"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="password"
								label="Password"
								rules={[
									{ required: true, message: "Please input your password!" }
								]}
							>
								<Input type="password" />
							</Form.Item>
							<Form.Item
								name="re-password"
								label="Repeat your password"
								rules={[
									{ required: true, message: "Please repeat your password!" },
									({ getFieldValue }) => ({
										validator(rule, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve()
											}
											return Promise.reject(
												"The two passwords that you entered do not match!"
											)
										}
									})
								]}
							>
								<Input type="password" />
							</Form.Item>
							{/*
								<Form.Item
									name="sellerOrBuyer"
									label="Are you a seller or a buyer?"
									rules={[{ required: true, message: "Please select a role!" }]}
								>
									<Select defaultValue="unset">
										<Option value="unset" disabled>
											Unset
										</Option>
										<Option value="seller">Seller</Option>
										<Option value="buyer">Buyer</Option>
									</Select>
								</Form.Item>
								*/}
							<Form.Item
								name="participantType"
								label="Participant Type"
								rules={[{ required: true, message: "Please select a role!" }]}
							>
								<Select defaultValue="unset">
									<Option value="unset" disabled>
										Unset
									</Option>
									{customizations?.participantTypes?.map(
										(participantType: any) => (
											<Option value={participantType}>{participantType}</Option>
										)
									)}
								</Select>
							</Form.Item>
							<Form.Item
								name="rememberMe"
								className="remember_me"
								valuePropName="checked"
								initialValue={false}
								rules={[
									{
										required: true,
										transform: (value) => value || undefined,
										type: "boolean",
										message: "Please check this box!"
									}
								]}
							>
								<Checkbox>
									I have read and agree with the{" "}
									<a href={customizations?.privacyPolicyUrl} target="_blank">
										Privacy Policy
									</a>
									.
								</Checkbox>
							</Form.Item>
							<Form.Item>
								<Button
									className="fullwidth"
									type="primary"
									htmlType="submit"
									style={{ marginTop: 32 }}
									disabled={
										!!form
											.getFieldsError()
											.filter(({ errors }) => errors.length).length
									}
									loading={loading}
								>
									Register
								</Button>
							</Form.Item>
							<Form.Item>
								<NavLink to="/">Already have an account?</NavLink>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</>
	)
}
