export function csvToJson(csv: string) {
	const lines = csv.split("\n")
	const result = []
	const headers = lines[0].split(",")

	for (let i = 1; i < lines.length; i++) {
		let obj: any = {}
		let currentLine: Array<any> = lines[i].split(",")

		for (let j = 0; j < headers.length; j++) {
			obj[headers[j]] = currentLine[j]
		}

		result.push(obj)
	}

	return result
}

export function jsonToCsv(arr: Array<any>) {
	const array = [Object.keys(arr[0])].concat(arr)

	return array
		.map((it) => {
			return Object.values(it).toString()
		})
		.join("\n")
}
