import './styles/event-item-header.scss'

import React, { useEffect, useState } from 'react'

import dateToCountdownString from '../../utils/time'
import { isNullOrEmpty } from '../../utils/string'

interface Props {
	conference: any
}

export default function EventItemHeader(props: Props) {
	const [currentCountdown, setCurrentCountdown] = useState<string>()

	useEffect(() => {
		if (isNullOrEmpty(props.conference?.eventStartsAt)) {
			return
		}

		const updateCountdown = () => {
			const relativeTime = dateToCountdownString(new Date(props.conference?.eventStartsAt))
			if (relativeTime == null) {
				setCurrentCountdown("L'evento è iniziato!")
				return
			}

			setCurrentCountdown(`L'evento inizierà tra: ${relativeTime}`)
		}

		updateCountdown()

		const intervalId = setInterval(updateCountdown, 1000)
		return () => { clearInterval(intervalId) }
	}, [props.conference])

	return (
		<div className="event-item">
			<div style={{ height: 72 }} />

			{
				!isNullOrEmpty(props.conference?.customizations?.foyer?.logo) &&
				<div className="event-item__logo"
					style={
						props.conference?.customizations?.foyer?.logo?.includes("http")
							? { backgroundImage: `url(${props.conference?.customizations?.foyer?.logo})` }
							: { background: props.conference?.customizations?.foyer?.logo }
					}>
				</div>
			}

			{
				!isNullOrEmpty(props.conference?.eventTitle) &&
				<h2 className="event-item__name">{props.conference?.eventTitle}</h2>
			}

			{
				!isNullOrEmpty(props.conference?.createdBy) &&
				<h4 className="event-item__owner">{props.conference?.createdBy}</h4>
			}

			{
				!isNullOrEmpty(props.conference?.eventDescription) &&
				<>
					<h3 className="event-item__description_title">Description</h3>
					<div className="event-item__description">{props.conference?.eventDescription}</div>
				</>
			}
			{
				!isNullOrEmpty(props.conference?.eventStartsAt) &&
				<div className="event-item__time">{currentCountdown}</div>
			}
		</div>
	)
}
