import React from "react"

export default function useOnClickOutside(
	refs: Array<React.RefObject<HTMLElement>>,
	handler: () => void
) {
	React.useEffect(() => {
		const listener = (event: any) => {
			const isInside = refs.some(
				(ref: React.RefObject<HTMLElement>) =>
					!ref.current || ref.current.contains(event.target)
			)

			// Do nothing if clicking ref's element or descendent elements
			if (isInside) {
				return
			}

			handler()
		}

		document.addEventListener("mousedown", listener)
		document.addEventListener("touchstart", listener)

		return () => {
			document.removeEventListener("mousedown", listener)
			document.removeEventListener("touchstart", listener)
		}
	}, [refs, handler])
}
