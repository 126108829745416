import { Button } from "antd"
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { history } from '../../App'
import { verifyUser } from '../../remote/http'
import './styles/verify-account.scss'

export default function VerifyAccountContainer(props: any) {
	const params: any = useParams()

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		verifyUser(params.jwt)
			.then(() => setLoading(false))
			.catch((error: any) => {
				alert(`Error: ${error}`)
				history.push("/login")
			})
	}, [])

	return (
		<>
			<div className="verify-account">
				<div className="message">
					{
						loading ? <>Loading...</>
							:
							<>
								<b>Email successfully verified!</b><br />
								Your account has been successfully verified.
								You're now ready to use the website!<br />
								<Button onClick={() => history.push("/")}>Go to login page</Button>
							</>
					}
				</div>
			</div>
		</>
	)
}
