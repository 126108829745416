import './styles/content-loader.scss';

import React from 'react';
import Loader from 'react-loader-spinner';

export default function ContentLoader() {
    return (
        <div className="loader-wrapper">
            <Loader
                type="Puff"
                color="#FFFFFF"
                height={100}
                width={100}
            />
        </div>
    )
}