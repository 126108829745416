import { TimePicker } from "antd"
import TextArea from "antd/lib/input/TextArea"
import Modal from 'antd/lib/modal/Modal'
import moment from "moment"
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from "../../context/AppContext"
import { fetchReservationsOnDay, makeReservation } from "../../remote/http"
import { addZeroIfNeeded } from "../../utils/time"
import './styles/reservation-modal.scss'

const MEETING_TIME_SLOT = 15

interface Props {
    username: string,
    date: Date,
    visible: boolean
    okButtonColor?: string
    onOk?: () => void
    onCancel: () => void
}

export default function ReservationModal(props: Props) {
    const [appContext] = useContext(AppContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(props.visible)

    const [reservationTimestamps, setReservationTimestamps] = useState<Array<Date> | null>(null)
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)

    const [note, setNote] = useState<string>()

    useEffect(() => {
        setReservationTimestamps(null)

        if (props.username && props.date) {
            fetchReservationsOnDay(appContext.token!, props.username, props.date)
                .then((timestampsStr: any) => {
                    setReservationTimestamps(timestampsStr.map((str: any) => new Date(str)))
                })
        }
    }, [props.date])

    useEffect(() => setVisible(props.visible), [props.visible])

    const okButtonStyle = props.okButtonColor && !props.okButtonColor.startsWith("http")
        ? { background: props.okButtonColor }
        : {}

    return (
        <Modal
            title={`New Reservation (${props.date?.toLocaleDateString()})`}
            visible={visible}
            centered={true}
            closable={true}
            className="modal"
            confirmLoading={loading}
            okButtonProps={{ style: okButtonStyle }}
            onOk={async () => {
                if (!selectedDate) {
                    alert("Choose the time first, please")
                    return
                }

                setLoading(true)

                try {
                    await makeReservation(appContext.token!, props.username, selectedDate, note)
                } catch (error) {
                    alert(error)
                    setLoading(false)
                    return
                }

                setSelectedDate(null)
                setReservationTimestamps(null)
                setLoading(false)

                alert("Done! We sent an email to the seller with your reservation request. We'll send you and email once it has been accepted.")

                props.onCancel()
                props.onOk && props.onOk()
            }}
            onCancel={() => {
                setSelectedDate(null)
                setReservationTimestamps(null)

                props.onCancel()
            }}
        >
            {!reservationTimestamps
                ? <div className="message">Loading reservations...</div>
                :
                <>
                    {reservationTimestamps.length == 0
                        ? <div className="message">No reservations for this day</div>
                        :
                        <>
                            <div className="message">Reservations for this day:</div>
                            <ul>
                                {reservationTimestamps.map((timestamp: Date) => {
                                    const until = new Date(timestamp.getTime() + MEETING_TIME_SLOT * 60000)
                                    return <li>{addZeroIfNeeded(timestamp.getHours())}:{addZeroIfNeeded(timestamp.getMinutes())} - {addZeroIfNeeded(until.getHours())}:{addZeroIfNeeded(until.getMinutes())}</li>
                                })}
                            </ul>
                        </>
                    }
                </>
            }

            <TextArea
                placeholder="Note"
                rows={6}
                onChange={(e) => setNote(e.target.value)}
            />

            <TimePicker
                format="HH:mm"
                onChange={(value: moment.Moment | null, _: string) => {
                    if (!value) {
                        return
                    }

                    const selectedDate = props.date!

                    const pickedTime = value.toDate()
                    selectedDate.setHours(pickedTime.getHours())
                    selectedDate.setMinutes(pickedTime.getMinutes())
                    setSelectedDate(selectedDate)
                }}
            />
        </Modal>
    )
}