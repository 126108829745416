import React, { createContext, useState, useEffect } from "react"
import { socketSendLogoutEvent } from "../remote/socket"
import { history } from "../App"

export const AppContext = createContext<[IAppContext, Function]>([{}, () => {}])

export class IAppContext {
	username?: string
	token?: string
	isAdmin?: boolean
	isHelpDesk?: boolean
	isSeller?: boolean

	accentColor?: string

	logout?: Function
}

export const AppContextProvider = (props: any) => {
	const [appContext, setAppContext] = useState<IAppContext>({
		username: localStorage.username,
		token: localStorage.token,
		isAdmin: localStorage.isAdmin,
		isHelpDesk: localStorage.isHelpDesk,
		isSeller: localStorage.isSeller,
		accentColor: localStorage.accentColor,

		logout: () => {
			socketSendLogoutEvent(appContext.token!)

			setAppContext({
				...appContext,

				username: undefined,
				token: undefined,
				isAdmin: undefined,
				isHelpDesk: undefined,
				isSeller: undefined,
				accentColor: undefined
			})

			history.push("/")
		}
	})

	// Make login persistent
	useEffect(() => {
		!appContext.username
			? localStorage.removeItem("username")
			: localStorage.setItem("username", appContext.username)

		!appContext.token
			? localStorage.removeItem("token")
			: localStorage.setItem("token", appContext.token)

		!appContext.isAdmin
			? localStorage.removeItem("isAdmin")
			: localStorage.setItem("isAdmin", String(appContext.isAdmin))

		!appContext.isHelpDesk
			? localStorage.removeItem("isHelpDesk")
			: localStorage.setItem("isHelpDesk", String(appContext.isHelpDesk))

		!appContext.isSeller
			? localStorage.removeItem("isSeller")
			: localStorage.setItem("isSeller", String(appContext.isSeller))

		!appContext.accentColor
			? localStorage.removeItem("accentColor")
			: localStorage.setItem("accentColor", appContext.accentColor)
	}, [appContext])

	return (
		<AppContext.Provider value={[appContext, setAppContext]}>
			{props.children}
		</AppContext.Provider>
	)
}
