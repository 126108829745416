import { Avatar, Button, Input, Upload } from "antd"
import Modal from "antd/lib/modal/Modal"
import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { standProductToImageUrl } from "../../remote/images"
import Icon from "../icon/Icon"

interface Props {
	visible: boolean
	productToEdit?: any
	okButtonColor?: string
	onCancel: () => void
	onConfirm: (
		name: string | undefined,
		price: string | undefined,
		salePrice: string | undefined,
		productImageBase64: any,
		destinationUrl: string | undefined
	) => void
}

export default function NewProductModal(props: Props) {
	const [appContext] = useContext(AppContext)

	const [visible, setVisible] = useState<boolean>(props.visible)

	const [name, setName] = useState<string | undefined>()
	const [price, setPrice] = useState<string | undefined>()
	const [salePrice, setSalePrice] = useState<string | undefined>()
	const [productImageBase64, setProductImageBase64] = useState<any>()
	const [destinationUrl, setDestinationUrl] = useState<string | undefined>()

	useEffect(() => setVisible(props.visible), [props.visible])

	useEffect(() => {
		setName(props.productToEdit?.name)
		setPrice(props.productToEdit?.price)
		setSalePrice(props.productToEdit?.salePrice)
		setProductImageBase64(
			props.productToEdit?.productImageBase64 ||
				(props.productToEdit
					? standProductToImageUrl(appContext.username!, props.productToEdit)
					: null)
		)
		setDestinationUrl(props.productToEdit?.destinationUrl)
	}, [props.productToEdit])

	const okButtonStyle =
		props.okButtonColor && !props.okButtonColor.startsWith("http")
			? { background: props.okButtonColor }
			: {}

	return (
		<Modal
			title={`${props.productToEdit ? "Edit" : "New"} Product`}
			visible={visible}
			centered={true}
			closable={true}
			className="modal"
			destroyOnClose
			okButtonProps={{ style: okButtonStyle }}
			onOk={() => {
				props.onConfirm(
					name,
					price,
					salePrice,
					productImageBase64,
					destinationUrl
				)

				setName(undefined)
				setPrice(undefined)
				setSalePrice(undefined)
				setProductImageBase64(null)
				setDestinationUrl(undefined)

				props.onCancel()
			}}
			onCancel={() => {
				setName(undefined)
				setPrice(undefined)
				setSalePrice(undefined)
				setProductImageBase64(null)
				setDestinationUrl(undefined)

				props.onCancel()
			}}
		>
			<Avatar src={productImageBase64} size={84} />
			<br />
			<Upload
				accept=".png,.jpg"
				showUploadList={false}
				beforeUpload={(file: any) => {
					const reader = new FileReader()
					reader.onloadend = () => {
						const base64 = reader.result
						setProductImageBase64(base64)
					}
					reader.readAsDataURL(file)

					return false
				}}
			>
				<Button style={{ color: "white", marginTop: 24 }}>
					<Icon type="upload" />
					<span>
						{productImageBase64
							? "Image selected, ready to upload"
							: "Select new image"}
					</span>
				</Button>
			</Upload>
			<Input
				placeholder="Name"
				disabled={!!props.productToEdit}
				defaultValue={name}
				onChange={(e) => setName(e.target.value)}
			/>
			<Input
				placeholder="Price"
				defaultValue={price}
				onChange={(e) => setPrice(e.target.value)}
			/>
			<Input
				placeholder="Sale Price"
				defaultValue={salePrice}
				onChange={(e) => setSalePrice(e.target.value)}
			/>
			<Input
				placeholder="Destination URL"
				defaultValue={destinationUrl}
				onChange={(e) =>
					setDestinationUrl(
						e.target.value.startsWith("http")
							? e.target.value
							: `http://${e.target.value}`
					)
				}
			/>
		</Modal>
	)
}
