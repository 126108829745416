import { Button, notification } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import AgoraComponent from "../../components/agora/AgoraComponent"
import Chat from "../../components/chat/Chat"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import { AGORA_APP_ID } from "../../config"
import { AppContext } from "../../context/AppContext"
import { joinRoom } from "../../remote/http"
import {
	socketJoinRoom,
	socketUnregisterRoomUpdates
} from "../../remote/socket"
import "./styles/webinar.scss"

interface JoinData {
	roomName: string
	agoraKey: string
	agoraScreenshareKey: string
	customizations: any
	whiteboardLink: string
}

export default () => {
	const [appContext] = useContext(AppContext)

	const params: any = useParams()

	const [joinData, setJoinData] = useState<JoinData>()
	const [isChatOpened, setIsChatOpened] = useState<boolean | null>(false)
	const [attendeesCount, setAttendeesCount] = useState<number>(-1)

	useEffect(() => {
		const roomHash = params.roomHash
		joinRoom(appContext.token!, roomHash).then((res) => {
			setJoinData(res)
		})

		socketJoinRoom(appContext.token!, roomHash, (data: any) => {
			if (data.attendeesCount != undefined) {
				setAttendeesCount(data.attendeesCount)
			} else {
				notification.open({
					message: "Warning",
					description:
						"This room has been updated. Click the button below to refresh the page.",
					placement: "bottomLeft",
					btn: (
						<Button
							type="primary"
							size="small"
							onClick={() => window.location.reload()}
						>
							Reload
						</Button>
					)
				})
			}
		})

		return () => {
			socketUnregisterRoomUpdates()
		}
	}, [])

	const paths = window.location.href.split("/")
	const isPlenaryHostMode = paths[paths.length - 2] == "plenary-host"

	const attendeeMode =
		(joinData?.customizations?.hosts || []).includes(appContext.username) ||
		(joinData?.customizations?.coHosts || []).includes(appContext.username)
			? isPlenaryHostMode
				? "plenary-host"
				: "video"
			: "audience"

	const classes = ["room", "webinar", isChatOpened ? "chat-opened" : ""]
		.filter(Boolean)
		.join(" ")

	const handleNavbarActon = (action: string) => {
		if (action === "chat") {
			setIsChatOpened(!isChatOpened)
		}
	}

	return (
		<>
			{!joinData?.customizations ? (
				<ContentLoader />
			) : (
				<>
					<div
						className="room-background-layer"
						style={
							joinData?.customizations?.bodyBackground
								? {
										background: joinData?.customizations.bodyBackground.startsWith(
											"http"
										)
											? `url(${joinData?.customizations.bodyBackground})`
											: joinData?.customizations.bodyBackground
								  }
								: {}
						}
					/>

					<div className={classes}>
						<div className="room-workspace">
							<Navbar
								onBack={() => history.push("/home")}
								onAction={handleNavbarActon}
								hiddenIcons={[
									"chat-conversations",
									"notifications",
									"video-source",
									"share-room-link",
									"help"
								]
									.concat(
										!(joinData?.customizations?.hosts || []).includes(
											appContext.username
										)
											? ["screen-recording"]
											: []
									)
									.concat(
										!(joinData?.customizations?.hosts || []).includes(
											appContext.username
										)
											? ["add-remove-co-host"]
											: []
									)}
								title={
									`${joinData?.roomName}${
										isPlenaryHostMode ? " - Host" : ""
									}` || `Room`
								}
								background={
									joinData?.customizations?.headerBackground ||
									appContext?.accentColor
								}
								whiteboardLink={joinData?.whiteboardLink}
							/>
							<div className="workspace">
								{joinData?.agoraKey && joinData?.agoraScreenshareKey && (
									<AgoraComponent
										videoProfile={
											joinData?.customizations?.videoQuality
												? joinData?.customizations?.videoQuality
												: (joinData?.customizations?.hosts || []).includes(
														appContext.username
												  ) ||
												  (joinData?.customizations?.coHosts || []).includes(
														appContext.username
												  )
												? "720p_1"
												: "240p_3"
										}
										channel={params.roomHash}
										transcode={isPlenaryHostMode ? "rtc" : "live"}
										agoraKey={joinData?.agoraKey}
										agoraScreenshareKey={joinData?.agoraScreenshareKey}
										toggleChat={() => setIsChatOpened(!isChatOpened)}
										appId={AGORA_APP_ID}
										hosts={joinData?.customizations?.hosts || []}
										coHosts={joinData?.customizations?.coHosts || []}
										uuid={appContext.username!}
										attendeeMode={attendeeMode}
										attendeesCount={attendeesCount}
									/>
								)}
							</div>
						</div>
						<div className="room-chat">
							<Chat
								onClose={() => setIsChatOpened(!isChatOpened)}
								roomHash={params.roomHash}
								chatBackground={joinData?.customizations?.chatBackground}
								moderators={joinData?.customizations?.moderators}
							/>
						</div>
					</div>
				</>
			)}
		</>
	)
}
