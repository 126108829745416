import { Button, Checkbox, DatePicker, Input, Table } from "antd"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { history } from "../../App"
import Icon from "../../components/icon/Icon"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { adminListRooms, adminUpdateRooms } from "../../remote/http"
import { arraysEqual, deepCopy } from "../../utils/array"
import "./styles/admin.scss"

let roomsToUpdate: any = {}
let untouchedRooms: any = []

export default function AdminManageRoomsContainer() {
	const [appContext] = useContext(AppContext)

	const [updateButtonLoading, setUpdateButtonLoading] = useState(false)

	const [rooms, setRooms] = useState<Array<any> | null>(null)

	const checkIfRoomHasBeenUpdated = (room: any) => {
		const untouchedRoom = untouchedRooms.find((ur: any) => ur.hash == room.hash)
		if (!untouchedRoom) {
			// Never happens
			return false
		}

		const areTheSame =
			!!untouchedRoom.isPublic == !!room.isPublic &&
			untouchedRoom.customizations.headerBackground ==
				room.customizations.headerBackground &&
			untouchedRoom.customizations.chatBackground ==
				room.customizations.chatBackground &&
			arraysEqual(untouchedRoom.embedLinks, room.embedLinks) &&
			untouchedRoom.startsAt == room.startsAt &&
			untouchedRoom.endsAt == room.endsAt &&
			untouchedRoom.whiteboardLink == room.whiteboardLink
		if (areTheSame) {
			delete roomsToUpdate[room.hash]
		}

		return !areTheSame
	}

	const columns = [
		{
			title: "Modified",
			dateKey: "modified",
			render: (_: any, roomRow: any) => (
				<>
					<span
						className="modified"
						style={{ fontSize: roomRow.isUpdated ? 24 : 16 }}
					>
						{roomRow.isUpdated ? "⚠️" : "No"}
					</span>
				</>
			)
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a: any, b: any) => a.name.localeCompare(b.name)
		},
		{
			title: "Type",
			dataIndex: "roomType",
			key: "roomType",
			sorter: (a: any, b: any) => a.roomType.localeCompare(b.roomType),
			render: (roomType: string) => <span>{roomType.toUpperCase()}</span>
		},
		{
			title: "Is Public",
			dataIndex: "isPublic",
			key: "isPublic",
			sorter: (a: any, b: any) => +a.isPublic - +b.isPublic,
			render: (_: boolean, roomRow: any) => {
				const onChange = (e: any) => {
					const isChecked = e.target.checked
					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].isPublic = isChecked

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.isPublic = isChecked
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}

				return (
					<Checkbox
						defaultChecked={roomRow.isPublic}
						onChange={onChange}
						key="isPublic"
					>
						{roomRow.isPublic ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Starts At",
			dataIndex: "startsAt",
			key: "startsAt",
			sorter: (a: any, b: any) =>
				new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime(),
			render: (startsAt: any, roomRow: any) => {
				const onChange = (momentDate: any) => {
					const date = momentDate ? momentDate.toDate() : new Date()

					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].startsAt = date

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.startsAt = date
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}
				return (
					<DatePicker
						format="YYYY-MM-DD HH:mm:ss"
						defaultValue={
							startsAt ? moment(startsAt) : moment("2000-01-01 00:00:00")
						}
						onChange={onChange}
						showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
					/>
				)
			}
		},
		{
			title: "Ends At",
			dataIndex: "endsAt",
			key: "endsAt",
			sorter: (a: any, b: any) =>
				new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime(),
			render: (endsAt: any, roomRow: any) => {
				const onChange = (momentDate: any) => {
					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].endsAt = momentDate.toDate()

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.endsAt = momentDate.toDate()
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}
				return (
					<DatePicker
						format="YYYY-MM-DD HH:mm:ss"
						defaultValue={
							endsAt ? moment(endsAt) : moment("2000-01-01 00:00:00")
						}
						onChange={onChange}
						showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
					/>
				)
			}
		},
		{
			title: "Header Background (Hex / URL)",
			dataKey: "['customizations', 'headerBackground']",
			key: "['customizations', 'headerBackground']",
			render: (_: string, roomRow: any) => {
				const onChange = (e: any) => {
					const value = e.target.value
					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].customizations.headerBackground = value

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.customizations.headerBackground = value
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}

				return (
					<Input
						defaultValue={roomRow.customizations.headerBackground}
						onChange={onChange}
					/>
				)
			}
		},
		{
			title: "Chat Background (Hex / URL)",
			dataKey: "['customizations', 'chatBackground']",
			key: "['customizations', 'chatBackground']",
			render: (_: string, roomRow: any) => {
				const onChange = (e: any) => {
					const value = e.target.value

					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].customizations.chatBackground = value

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.customizations.chatBackground = value
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}

				return (
					<Input
						defaultValue={roomRow.customizations.chatBackground}
						onChange={onChange}
					/>
				)
			}
		},
		{
			title: "Embed Links",
			dataKey: "embedLinks",
			key: "embedLinks",
			render: (_: string, roomRow: any) => {
				const onChange = (e: any) => {
					const valueWithoutBlankSpaces = e.target.value.replaceAll(" ", "")
					const value = valueWithoutBlankSpaces.includes(",")
						? valueWithoutBlankSpaces.split(",")
						: [valueWithoutBlankSpaces]

					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].embedLinks = value

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.embedLinks = value
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}

				return (
					<Input
						defaultValue={roomRow.embedLinks.join(",")}
						onChange={onChange}
					/>
				)
			}
		},
		{
			title: "Whiteboard Link",
			dataKey: "whiteboardLink",
			key: "whiteboardLink",
			render: (_: string, roomRow: any) => {
				const onChange = (e: any) => {
					const value = e.target.value.replaceAll(" ", "")

					roomsToUpdate[roomRow.hash] = roomsToUpdate[roomRow.hash] || {
						...roomRow
					}
					roomsToUpdate[roomRow.hash].whiteboardLink = value

					setRooms(
						rooms?.map((room: any) => {
							if (roomRow.hash == room.hash) {
								room.whiteboardLink = value
								room.isUpdated = checkIfRoomHasBeenUpdated(room)
							}
							return room
						}) || []
					)
				}

				return (
					<Input defaultValue={roomRow.whiteboardLink} onChange={onChange} />
				)
			}
		},
		{
			title: "Remove",
			dataIndex: "removed",
			key: "remove",
			render: (_: any, roomRow: any) => (
				<Icon
					type="close"
					customClass="remove-room hoverable"
					onClick={async () => {
						const reqBody: any = {}
						reqBody[roomRow.hash] = roomRow
						reqBody[roomRow.hash].mustRemove = true

						if (
							!window.confirm(
								`Are you sure you want to delete room "${roomRow.name}"?`
							)
						) {
							return
						}

						await adminUpdateRooms(appContext.token!, reqBody)

						window.alert("Done!")

						setRooms((rooms || []).filter((r) => r.hash != roomRow.hash))
					}}
				/>
			)
		}
	]

	useEffect(() => {
		adminListRooms(appContext.token!)
			.then((allRooms: any) => {
				untouchedRooms = deepCopy(allRooms)
				setRooms(allRooms)
			})
			.catch((error: any) => {
				alert("Error: " + error)
				history.goBack()
			})
	}, [])

	return (
		<>
			<div className="admin">
				<Navbar
					onBack={() => {
						history.goBack()
					}}
					title={"Admin Panel - Rooms"}
					hiddenIcons={[
						"chat-conversations",
						"chat",
						"video-source",
						"help",
						"share-room-link",
						"whiteboard",
						"add-remove-co-host"
					]}
				/>
				<div className="table-wrapper">
					<Button
						className="upload-button"
						loading={updateButtonLoading}
						onClick={async () => {
							const editedRoomsLength = Object.keys(roomsToUpdate).length
							if (editedRoomsLength == 0) {
								return alert("No updates to commit")
							}

							if (
								!window.confirm(
									`Do you want to update ${editedRoomsLength} room/s?`
								)
							) {
								return
							}

							setUpdateButtonLoading(true)

							try {
								await adminUpdateRooms(appContext.token!, roomsToUpdate)

								// Clear old rooms
								Object.keys(roomsToUpdate).forEach((id) => {
									const room = roomsToUpdate[id]
									untouchedRooms.map((untouchedRoom: any) => {
										if (untouchedRoom.hash == room.hash) {
											untouchedRoom.customizations.headerBackground =
												room.customizations.headerBackground
											untouchedRoom.customizations.chatBackground =
												room.customizations.chatBackground
										}
										return untouchedRoom
									})
									delete roomsToUpdate[id]
								})

								setRooms(
									rooms?.map((room: any) => {
										if (room.isUpdated) {
											room.isUpdated = false
										}
										return room
									}) || []
								)

								alert("Done!")
							} catch (err) {
								alert("Error: " + err)
							} finally {
								setUpdateButtonLoading(false)
							}
						}}
					>
						Update rooms
					</Button>

					<Button
						className="upload-button"
						style={{ marginRight: 16 }}
						onClick={() => history.push("/admin/create-room")}
					>
						Create room
					</Button>

					<Table
						columns={columns}
						dataSource={rooms || []}
						scroll={{ x: "max-content" }}
						rowKey={(record: any) => record.hash}
						pagination={{
							defaultPageSize: 20,
							showSizeChanger: true,
							pageSizeOptions: ["10", "20", "50", "100", "99999999"]
						}}
						loading={rooms == null}
					/>
				</div>
			</div>
		</>
	)
}
