import { Checkbox, DatePicker, Select } from "antd"
import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import message from "antd/lib/message"
import moment from "moment"
import React, { useContext, useState } from "react"
import { history } from "../../App"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { adminCreateRoom } from "../../remote/http"
import { Log } from "../../utils/logging"
import "./styles/admin.scss"
const { Option } = Select

export default function AdminCreateRoomContainer() {
	const [appContext] = useContext(AppContext)

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const onFinish = async (values: any) => {
		setLoading(true)

		try {
			await adminCreateRoom(appContext.token!, values)

			setLoading(false)

			alert(
				"Done!\n\n\
                   NOTE: If you want to:\n\
                   - Make this room visible\n\
                   - Customize the graphics of the room\n\
                   - Select the hosts of this room\n\
                   Then you should go to the 'Manage Rooms' section of the Admin panel"
			)

			history.goBack()
		} catch (e) {
			Log.print("Error! ", e)

			const errorMsg = e.toString()
			message.error(errorMsg)

			setLoading(false)
		}
	}

	return (
		<>
			<div className="admin">
				<Navbar
					onBack={() => {
						history.goBack()
					}}
					title={"Admin Panel - Create Room"}
					hiddenIcons={[
						"chat-conversations",
						"chat",
						"video-source",
						"help",
						"share-room-link",
						"whiteboard",
						"add-remove-co-host"
					]}
				/>

				<div className="create-room-form">
					<Form className="form" onFinish={onFinish}>
						<Form.Item
							name="name"
							label="Room Name"
							rules={[{ required: true, message: "Please fill this input" }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="roomType"
							label="Room Type"
							rules={[{ required: true, message: "Please fill this input" }]}
						>
							<Select style={{ width: "100%" }}>
								<Option value="networking">NETWORKING</Option>
								<Option value="webinar">WEBINAR</Option>
								<Option value="main">PLENARY</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name="embedLinks"
							label="Embed Links (split by '|', leave empty to generate automatically)"
							rules={[{ required: false }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="startsAt"
							label="Room Starts At"
							rules={[{ required: true, message: "Please fill this input" }]}
						>
							<DatePicker
								style={{ width: "100%", height: 48, borderRadius: 8 }}
								format="YYYY-MM-DD HH:mm:ss"
								showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
							/>
						</Form.Item>
						<Form.Item
							name="endsAt"
							label="Room Ends At"
							rules={[{ required: true, message: "Please fill this input" }]}
						>
							<DatePicker
								style={{ width: "100%", height: 48, borderRadius: 8 }}
								format="YYYY-MM-DD HH:mm:ss"
								showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
							/>
						</Form.Item>
						<Form.Item
							name="withoutWhiteboard"
							label="Do NOT create WhiteBoard"
						>
							<span style={{ color: "white" }}>
								<Checkbox /> No WhiteBoard
							</span>
						</Form.Item>
						<Form.Item name="isPublic" label="Make room visible to everyone">
							<span style={{ color: "white" }}>
								<Checkbox value={true} /> Make room public
							</span>
						</Form.Item>
						<Form.Item name="submit" style={{ marginTop: 32 }}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={
									!!form.getFieldsError().filter(({ errors }) => errors.length)
										.length
								}
								loading={loading}
							>
								Create Room
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</>
	)
}
