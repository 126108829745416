import "./styles/admin.scss"

import { Button, Input, Space, Table, Avatar } from "antd"
import Checkbox from "antd/lib/checkbox"
import React, { useEffect, useRef, useState, useContext } from "react"
// @ts-ignore
import Highlighter from "react-highlight-words"

import { SearchOutlined } from "@ant-design/icons"

import { history } from "../../App"
import Navbar from "../../components/navbar/Navbar"
import StatefulMultiSelect from "../../components/stateful-multi-select/StatefulMultiSelect"
import {
	adminListRooms,
	adminListAllUsers,
	adminUpdateUsers
} from "../../remote/http"
import {
	socketJoinAdminDashboard,
	socketUnregisterAdminDashboard
} from "../../remote/socket"
import { arraysEqual } from "../../utils/array"
import { usernameToProfilePicUrl } from "../../remote/images"
import { AppContext } from "../../context/AppContext"
import Icon from "../../components/icon/Icon"
import { jsonToCsv } from "../../utils/csv"

const usersToUpdate: any = {}
let untouchedUsers: any = []

export default function AdminManageUsersContainer() {
	const [appContext] = useContext(AppContext)

	const [buttonLoading, setButtonLoading] = useState(false)

	const [isConferencePublic, setIsConferencePublic] = useState<boolean>(false)
	const [users, setUsers] = useState<Array<any> | null>(null)
	const [rooms, setRooms] = useState<Array<any> | null>(null)

	const [searchText, setSearchText] = useState("")
	const [searchedColumn, setSearchedColumn] = useState("")

	const inputRef = useRef(null)

	useEffect(() => {
		socketJoinAdminDashboard(
			appContext.token!,
			(newOnlineUsers: Array<string>, newOfflineUsers: Array<string>) => {
				setUsers(
					(users) =>
						users?.map((user: any) => {
							if (newOnlineUsers.includes(user.username)) {
								user.isOnline = true
							}

							if (newOfflineUsers.includes(user.username)) {
								user.isOnline = false
							}

							return user
						}) || []
				)

				return () => {
					socketUnregisterAdminDashboard()
				}
			}
		)
	}, [])

	const getColumnSearchProps = (dataIndex: any) => ({
		filterDropdown: (props: {
			setSelectedKeys: any
			selectedKeys: any
			confirm: any
			clearFilters: any
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node: any) => {
						inputRef.current = node
					}}
					placeholder={`Search ${dataIndex}`}
					value={props.selectedKeys[0]}
					onChange={(e: any) =>
						props.setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(props.selectedKeys, props.confirm, dataIndex)
					}
					style={{ width: 220, marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(props.selectedKeys, props.confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 120 }}
					>
						Search
					</Button>
					<Button
						onClick={() => handleReset(props.clearFilters)}
						size="small"
						style={{ width: 100, color: "white" }}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: any) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value: any, record: any) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible: any) => {
			if (visible) {
				setTimeout(() => (inputRef.current as any)?.select())
			}
		},
		render: (text: any, userRow: any) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				<span>{text}</span>
			)
	})

	const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm()
		setSearchText(selectedKeys[0])
		setSearchedColumn(dataIndex)
	}

	const handleReset = (clearFilters: any) => {
		clearFilters()
		setSearchText("")
	}

	const checkIfUserHasBeenUpdated = (user: any) => {
		const untouchedUser = untouchedUsers.find(
			(untouchedUser: any) => untouchedUser._id == user._id
		)
		if (!untouchedUser) {
			// Never happens
			return false
		}

		const areTheSame =
			Boolean(untouchedUser.isVerified) == Boolean(user.isVerified) &&
			Boolean(untouchedUser.canLogIn) == Boolean(user.canLogIn) &&
			Boolean(untouchedUser.isHelpDesk) == Boolean(user.isHelpDesk) &&
			Boolean(untouchedUser.isAdmin) == Boolean(user.isAdmin) &&
			Boolean(untouchedUser.isPremium) == Boolean(user.isPremium) &&
			arraysEqual(untouchedUser.roomsAllowed, user.roomsAllowed) &&
			arraysEqual(untouchedUser.roomsAllowedHost, user.roomsAllowedHost) &&
			arraysEqual(untouchedUser.roomsAllowedCoHost, user.roomsAllowedCoHost) &&
			arraysEqual(
				untouchedUser.roomsAllowedModerator,
				user.roomsAllowedModerator
			)
		if (areTheSame) {
			delete usersToUpdate[user._id]
		}
		return !areTheSame
	}

	const columns = [
		{
			title: "Modified",
			dateKey: "modified",
			sorter: (a: any, b: any) => +a.isUpdated - +b.isUpdated,
			render: (_: any, userRow: any) => (
				<span
					className="modified"
					style={{ fontSize: userRow.isUpdated ? 24 : 16 }}
				>
					{userRow.isUpdated ? "⚠️" : "No"}
				</span>
			)
		},
		{
			title: "Profile Pic",
			dataIndex: "profilePic",
			key: "profilePic",
			render: (_: any, userRow: any) => (
				<Avatar
					style={{ marginLeft: 8 }}
					className="avatar"
					src={usernameToProfilePicUrl(userRow.username, false)}
					size={48}
				/>
			)
		},
		{
			title: "Online",
			dataIndex: "isOnline",
			key: "isOnline",
			sorter: (a: any, b: any) => +a.isOnline - +b.isOnline,
			render: (isOnline: boolean) => (
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: isOnline ? "green" : "red",
						borderRadius: "50%",
						marginLeft: 12
					}}
				/>
			)
		},
		{
			title: "Verified",
			dataIndex: "isVerified",
			key: "isVerified",
			sorter: (a: any, b: any) => +a.isVerified - +b.isVerified,
			render: (isVerified: boolean, userRow: any) => {
				const onChange = (e: any) => {
					const isChecked = e.target.checked
					usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
					usersToUpdate[userRow._id].isVerified = isChecked

					setUsers(
						users?.map((user: any) => {
							if (userRow._id == user._id) {
								user.isVerified = isChecked
								user.isUpdated = checkIfUserHasBeenUpdated(user)
							}
							return user
						}) || []
					)
				}

				return (
					<Checkbox
						defaultChecked={isVerified}
						onChange={onChange}
						key={`${userRow.username}-isVerified`}
					>
						{isVerified ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Can Log In",
			dataIndex: "canLogIn",
			key: "canLogIn",
			sorter: (a: any, b: any) => +a.canLogIn - +b.canLogIn,
			render: (canLogIn: boolean, userRow: any) => {
				const onChange = (e: any) => {
					const isChecked = e.target.checked
					usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
					usersToUpdate[userRow._id].canLogIn = isChecked

					setUsers(
						users?.map((user: any) => {
							if (userRow._id == user._id) {
								user.canLogIn = isChecked
								user.isUpdated = checkIfUserHasBeenUpdated(user)
							}
							return user
						}) || []
					)
				}

				return (
					<Checkbox
						defaultChecked={canLogIn}
						onChange={onChange}
						className={isConferencePublic ? "disabled-checkbox" : ""}
						disabled={isConferencePublic}
						key={`${userRow.username}-canLogIn`}
					>
						{canLogIn ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Seller",
			dataIndex: "isSeller",
			key: "isSeller",
			sorter: (a: any, b: any) => +a.isSeller - +b.isSeller,
			render: (isSeller: boolean, userRow: any) => {
				return (
					<Checkbox
						disabled
						checked={isSeller}
						className="disabled-checkbox"
						key={`${userRow.username}-isSeller`}
					>
						{isSeller ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a: any, b: any) => a.email.localeCompare(b.email),
			...getColumnSearchProps("email")
		},
		{
			title: "Username",
			dataIndex: "username",
			key: "username",
			sorter: (a: any, b: any) => a.username.localeCompare(b.username),
			...getColumnSearchProps("username")
		},
		{
			title: "Help Desk",
			dataIndex: "isHelpDesk",
			key: "isHelpDesk",
			sorter: (a: any, b: any) => +a.isHelpDesk - +b.isHelpDesk,
			render: (isHelpDesk: boolean, userRow: any) => {
				const onChange = (e: any) => {
					const isChecked = e.target.checked
					usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
					usersToUpdate[userRow._id].isHelpDesk = isChecked

					setUsers(
						users?.map((user: any) => {
							if (userRow._id == user._id) {
								user.isHelpDesk = isChecked
								user.isUpdated = checkIfUserHasBeenUpdated(user)
							}
							return user
						}) || []
					)
				}

				return (
					<Checkbox
						defaultChecked={isHelpDesk}
						onChange={onChange}
						key={`${userRow.username}-isHelpDesk`}
					>
						{isHelpDesk ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Admin",
			dataIndex: "isAdmin",
			key: "isAdmin",
			sorter: (a: any, b: any) => +a.isAdmin - +b.isAdmin,
			render: (isAdmin: boolean, userRow: any) => {
				const onChange = (e: any) => {
					const isChecked = e.target.checked
					usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
					usersToUpdate[userRow._id].isAdmin = isChecked

					setUsers(
						users?.map((user: any) => {
							if (userRow._id == user._id) {
								user.isAdmin = isChecked
								user.isUpdated = checkIfUserHasBeenUpdated(user)
							}
							return user
						}) || []
					)
				}

				return (
					<Checkbox
						defaultChecked={isAdmin}
						onChange={onChange}
						key={`${userRow.username}-isAdmin`}
					>
						{isAdmin ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Premium",
			dataIndex: "isPremium",
			key: "isPremium",
			sorter: (a: any, b: any) => +a.isPremium - +b.isPremium,
			render: (isPremium: boolean, userRow: any) => {
				const onChange = (e: any) => {
					const isChecked = e.target.checked
					usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
					usersToUpdate[userRow._id].isPremium = isChecked

					setUsers(
						users?.map((user: any) => {
							if (userRow._id == user._id) {
								user.isPremium = isChecked
								user.isUpdated = checkIfUserHasBeenUpdated(user)
							}
							return user
						}) || []
					)
				}

				return (
					<Checkbox
						defaultChecked={isPremium}
						onChange={onChange}
						key={`${userRow.username}-isPremium`}
					>
						{isPremium ? "YES" : "NO"}
					</Checkbox>
				)
			}
		},
		{
			title: "Rooms",
			dataIndex: "roomsAllowed",
			key: "roomsAllowed",
			render: (_: Array<any>, userRow: any) => (
				<StatefulMultiSelect
					key={`${userRow.username}-multiSelect-roomsAllowed`}
					values={(rooms || []).map((r: any) => r.name)}
					defaultValues={userRow.roomsAllowed}
					onChange={(newValues: any) => {
						usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
						usersToUpdate[userRow._id].roomsAllowed = newValues

						setUsers(
							users?.map((user: any) => {
								if (userRow._id == user._id) {
									user.roomsAllowed = newValues
									user.isUpdated = checkIfUserHasBeenUpdated(user)
								}
								return user
							}) || []
						)
					}}
				/>
			)
		},
		{
			title: "Host",
			dataIndex: "roomsAllowedHost",
			key: "roomsAllowedHost",
			render: (roomsAllowedHost: Array<any>, userRow: any) => (
				<StatefulMultiSelect
					key={`${userRow.username}-multiSelect-roomsAllowedHost`}
					values={(rooms || []).map((r: any) => r.name)}
					defaultValues={roomsAllowedHost}
					onChange={(newValues: any) => {
						usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
						usersToUpdate[userRow._id].roomsAllowedHost = newValues

						setUsers(
							users?.map((user: any) => {
								if (userRow._id == user._id) {
									user.roomsAllowedHost = newValues
									user.isUpdated = checkIfUserHasBeenUpdated(user)
								}
								return user
							}) || []
						)
					}}
				/>
			)
		},
		{
			title: "Co-Host",
			dataIndex: "roomsAllowedCoHost",
			key: "roomsAllowedCoHost",
			render: (roomsAllowedCoHost: Array<any>, userRow: any) => (
				<StatefulMultiSelect
					key={`${userRow.username}-multiSelect-roomsAllowedCoHost`}
					values={(rooms || []).map((r: any) => r.name)}
					defaultValues={roomsAllowedCoHost}
					onChange={(newValues: any) => {
						usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
						usersToUpdate[userRow._id].roomsAllowedCoHost = newValues

						setUsers(
							users?.map((user: any) => {
								if (userRow._id == user._id) {
									user.roomsAllowedCoHost = newValues
									user.isUpdated = checkIfUserHasBeenUpdated(user)
								}
								return user
							}) || []
						)
					}}
				/>
			)
		},
		{
			title: "Moderator",
			dataIndex: "roomsAllowedModerator",
			key: "roomsAllowedModerator",
			render: (roomsAllowedModerator: Array<any>, userRow: any) => (
				<StatefulMultiSelect
					key={`${userRow.username}-multiSelect-roomsAllowedModerator`}
					values={(rooms || []).map((r: any) => r.name)}
					defaultValues={roomsAllowedModerator}
					onChange={(newValues: any) => {
						usersToUpdate[userRow._id] = usersToUpdate[userRow._id] || userRow
						usersToUpdate[userRow._id].roomsAllowedModerator = newValues

						setUsers(
							users?.map((user: any) => {
								if (userRow._id == user._id) {
									user.roomsAllowedModerator = newValues
									user.isUpdated = checkIfUserHasBeenUpdated(user)
								}
								return user
							}) || []
						)
					}}
				/>
			)
		},
		{
			title: "Remove",
			dataIndex: "removed",
			key: "remove",
			render: (_: any, userRow: any) => (
				<Icon
					type="close"
					customClass="remove-user hoverable"
					onClick={async () => {
						const reqBody: any = {}
						reqBody[userRow._id] = userRow
						reqBody[userRow._id].mustRemove = true

						if (
							!window.confirm(
								`Are you sure you want to delete user "${userRow.username}"?`
							)
						) {
							return
						}

						await adminUpdateUsers(appContext.token!, reqBody)

						window.alert("Done!")

						setUsers(
							(users || []).filter((u) => u.username != userRow.username)
						)
					}}
				/>
			)
		}
	]

	useEffect(() => {
		Promise.all([
			adminListAllUsers(appContext.token!),
			adminListRooms(appContext.token!)
		])
			.then((values) => {
				const data = values[0]
				const rooms = values[1]

				untouchedUsers = data.participants.map((a: any) => ({ ...a }))

				setIsConferencePublic(data.isConferencePublic)
				setUsers(data.participants)
				setRooms(rooms)
			})
			.catch((err) => {
				alert("Error: " + err)
				history.goBack()
			})
	}, [])

	return (
		<>
			<div className="admin">
				<Navbar
					onBack={() => {
						history.goBack()
					}}
					title={"Admin Panel - Manage Users"}
					hiddenIcons={[
						"chat-conversations",
						"chat",
						"video-source",
						"help",
						"share-room-link",
						"whiteboard",
						"add-remove-co-host"
					]}
				/>
				<div className="table-wrapper">
					<Button
						onClick={() => {
							if (!users) {
								return
							}

							const csv = jsonToCsv(
								users.map((user) => ({
									Username: user.username,
									Email: user.email,
									Verified: user.isVerified ? "YES" : "NO",
									Seller: user.isSeller ? "YES" : "NO",
									"Help Desk": user.isHelpDesk ? "YES" : "NO",
									Admin: user.isAdmin ? "YES" : "NO",
									Premium: user.isPremium ? "YES" : "NO",
									Rooms: (user.roomsAllowed || []).join(" - "),
									Host: (user.roomsAllowedHost || []).join(" - "),
									"Co-Host": (user.roomsAllowedCoHost || []).join(" - "),
									Moderator: (user.roomsAllowedModerator || []).join(" - ")
								}))
							)

							const element = document.createElement("a")
							element.setAttribute(
								"href",
								"data:text/plain;charset=utf-8," + encodeURIComponent(csv)
							)
							element.setAttribute("download", "Users.csv")

							element.style.display = "none"
							document.body.appendChild(element)

							element.click()

							document.body.removeChild(element)
						}}
					>
						Export CSV
					</Button>

					<Button
						className="upload-button"
						loading={buttonLoading}
						onClick={async () => {
							const editedUsersLength = Object.keys(usersToUpdate).length
							if (editedUsersLength == 0) {
								return window.alert("No updates to commit")
							}

							if (
								!window.confirm(
									`Do you want to update ${editedUsersLength} user/s?`
								)
							) {
								return
							}

							setButtonLoading(true)

							try {
								await adminUpdateUsers(appContext.token!, usersToUpdate)

								// Clear old updated users
								Object.keys(usersToUpdate).forEach((id) => {
									const user = usersToUpdate[id]
									untouchedUsers.map((untouchedUser: any) => {
										if (untouchedUser._id == user._id) {
											untouchedUser.isVerified = user.isVerified
											untouchedUser.canLogIn = user.canLogIn
											untouchedUser.isAdmin = user.isAdmin
											untouchedUser.isPremium = user.isPremium
											untouchedUser.isHelpDesk = user.isHelpDesk
											untouchedUser.roomsAllowed = user.roomsAllowed
											untouchedUser.roomsAllowedHost = user.roomsAllowedHost
											untouchedUser.roomsAllowedCoHost = user.roomsAllowedCoHost
											untouchedUser.roomsAllowedModerator =
												user.roomsAllowedModerator
										}
										return untouchedUser
									})
									delete usersToUpdate[id]
								})

								setUsers(
									users?.map((user: any) => {
										if (user.isUpdated) {
											user.isUpdated = false
										}
										return user
									}) || []
								)

								alert("Done!")
							} catch (err) {
								alert("Error: " + err)
							} finally {
								setButtonLoading(false)
							}
						}}
					>
						Update users
					</Button>

					<Table
						columns={columns}
						dataSource={users || []}
						scroll={{ x: "max-content" }}
						rowKey={(record: any) => record._id}
						pagination={{
							defaultPageSize: 20,
							showSizeChanger: true,
							pageSizeOptions: ["10", "20", "50", "100", "99999999"]
						}}
						loading={users == null}
					/>
				</div>
			</div>
		</>
	)
}
