import { Avatar, Button, Input, Upload } from "antd"
import Modal from "antd/lib/modal/Modal"
import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { fetchUserInfo, updateUserInfo } from "../../remote/http"
import { usernameToProfilePicUrl } from "../../remote/images"
import Icon from "../icon/Icon"

interface Props {
	visible: boolean
	okButtonColor?: string
	onCancel: () => void
	onOk?: () => void
}

export default function EditAccountModal(props: Props) {
	const [appContext] = useContext(AppContext)

	const [visible, setVisible] = useState<boolean>(props.visible)

	const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
	const [profileData, setProfileData] = useState<any>()

	const [name, setName] = useState<string | undefined>()
	const [surname, setSurname] = useState<string | undefined>()
	const [website, setWebsite] = useState<string | undefined>()
	const [profilePicBase64, setProfilePicBase64] = useState<any>()

	useEffect(() => {
		fetchUserInfo(appContext.token!, appContext.username!)
			.then(setProfileData)
			.catch(console.error)
	}, [appContext.username])

	useEffect(() => setVisible(props.visible), [props.visible])

	const okButtonStyle =
		props.okButtonColor && !props.okButtonColor.startsWith("http")
			? { background: props.okButtonColor }
			: {}

	return (
		<Modal
			title={`Edit Profile`}
			visible={visible}
			centered={true}
			closable={true}
			className="modal"
			okButtonProps={{ style: okButtonStyle }}
			onOk={async () => {
				setConfirmLoading(true)

				await updateUserInfo(appContext.token!, {
					name,
					surname,
					email: undefined,
					website,
					profilePicBase64,
					stand: null
				})

				setConfirmLoading(false)

				props.onOk && props.onOk()
				props.onCancel()
			}}
			confirmLoading={confirmLoading}
			onCancel={() => {
				setProfilePicBase64(null)

				props.onCancel()
			}}
		>
			{!profileData ? (
				<h3>Loading...</h3>
			) : (
				<>
					<Avatar
						src={
							profilePicBase64 ||
							usernameToProfilePicUrl(appContext.username!, false)
						}
						size={84}
					/>
					<br />
					<Upload
						accept=".png,.jpg"
						showUploadList={false}
						beforeUpload={(file: any) => {
							const reader = new FileReader()
							reader.onloadend = () => {
								const base64 = reader.result
								setProfilePicBase64(base64)
							}
							reader.readAsDataURL(file)

							return false
						}}
					>
						<Button style={{ color: "white", marginTop: 24 }}>
							<Icon type="upload" />
							<span>
								{profilePicBase64
									? "Image selected, ready to upload"
									: "Select new image"}
							</span>
						</Button>
					</Upload>
					<Input
						defaultValue={profileData.name}
						placeholder="Name"
						onChange={(e) => setName(e.target.value)}
					/>
					<Input
						defaultValue={profileData.surname}
						placeholder="Surname"
						onChange={(e) => setSurname(e.target.value)}
					/>
					<Input
						defaultValue={profileData.website}
						placeholder="Website (Personal, LinkedIn, Instagram, ...)"
						onChange={(e) => setWebsite(e.target.value)}
					/>
				</>
			)}
		</Modal>
	)
}
