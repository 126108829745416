import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { history } from "../../App"
import EventItemHeader from "../../components/event/EventItemHeader"
import Icon from "../../components/icon/Icon"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { fetchHomeContent } from "../../remote/http"
import {
	socketJoinHomeUpdates,
	socketUnregisterHomeUpdates
} from "../../remote/socket"
import "./styles/event.scss"

export default function FoyerContainer() {
	const [appContext, setAppContext] = useContext(AppContext)

	const [conference, setConference] = useState<any>()
	const [rooms, setRooms] = useState<any>()
	const [stands, setStands] = useState<any>()

	useEffect(() => {
		const updateHome = () =>
			fetchHomeContent(appContext, appContext.token!)
				.then((res: any) => {
					const ctx = { ...appContext }
					ctx.accentColor = conference?.customizations?.foyer?.headerBackground
					setAppContext(ctx)

					setConference(res.conference)
					setRooms(res.rooms)
					setStands(res.stands)
				})
				.catch(console.error)

		updateHome()

		socketJoinHomeUpdates(appContext.token!, updateHome)

		return () => {
			socketUnregisterHomeUpdates()
		}
	}, [])

	const goToRoom = (room: any) => {
		if (!room) {
			return
		}

		if (room.roomType == "networking") {
			const link = room.embedLinks[0]

			if (link.includes("jitsi")) {
				history.push(`/${room.roomType}/${room.hash}`)
			} else {
				// External platform, open link
				window.open(link.startsWith("http") ? link : `http://${link}`, "_blank")
			}
		} else {
			const isModerator = (room.customizations.moderators || []).includes(
				appContext.username
			)
			const isHost = (room.customizations.hosts || []).includes(
				appContext.username
			)
			const isCoHost = (room.customizations.coHosts || []).includes(
				appContext.username
			)
			if (room.roomType == "main" && (isHost || isCoHost)) {
				Modal.confirm({
					title: "Warning",
					icon: <ExclamationCircleOutlined />,
					content: "Do you want to participate as a VIEWER or as a HOST?",
					centered: true,
					maskClosable: true,
					okText: "Host",
					cancelText: "Viewer",
					cancelButtonProps: { id: "discardButton" },
					onOk: () => {
						history.push(`/plenary-host/${room.hash}`)
					},
					onCancel: (arg: any) => {
						const clickedBtn = typeof arg == "function"
						if (clickedBtn) {
							arg() // Close the modal
							history.push(`/main/${room.hash}`)
						}
					}
				})
			} else if (isModerator) {
				Modal.confirm({
					title: "Warning",
					icon: <ExclamationCircleOutlined />,
					content: "You are joining as a moderator!",
					centered: true,
					okText: "OK",
					cancelText: "Cancel",
					maskClosable: true,
					onOk: () => history.push(`/${room.roomType}/${room.hash}`),
					onCancel: () => {}
				})
			} else if (room.roomType == "webinar" && (isHost || isCoHost)) {
				Modal.confirm({
					title: "Warning",
					icon: <ExclamationCircleOutlined />,
					content: `You're one of the ${
						isHost ? "host" : "co-host"
					} of this webinar. Are you ready to join?`,
					centered: true,
					maskClosable: true,
					okText: "Yes",
					cancelText: "No",
					cancelButtonProps: { id: "discardButton" },
					onOk: () => history.push(`/webinar/${room.hash}`),
					onCancel: () => {}
				})
			} else {
				history.push(`/${room.roomType}/${room.hash}`)
			}
		}
	}

	const goToStand = (stand: any) => {
		if (stand.username == appContext.username) {
			Modal.confirm({
				title: "Warning",
				icon: <ExclamationCircleOutlined />,
				content: "This is your stand. Do you want to VIEW or EDIT it?",
				centered: true,
				maskClosable: true,
				okText: "Edit",
				cancelText: "View",
				cancelButtonProps: { id: "discardButton" },
				onOk: () => history.push(`/edit-stand`),
				onCancel: (arg: any) => {
					const clickedBtn = typeof arg == "function"
					if (clickedBtn) {
						arg() // Close the modal
						history.push(`/stand/${stand.username}`)
					}
				}
			})
		} else {
			history.push(`/stand/${stand.username}`)
		}
	}

	return !conference ? (
		<ContentLoader />
	) : (
		<div className="event">
			<div className="navbar-wrapper">
				<Navbar
					title={"Home"}
					hiddenIcons={[
						"chat",
						"video-source",
						"whiteboard",
						"add-remove-co-host",
						"chat-conversations"
					]}
					background={conference?.customizations?.foyer?.headerBackground}
				/>
			</div>

			<div className="workspace">
				<div
					className="workspace-aside"
					style={{
						background: conference?.customizations?.foyer?.leftSideBackground?.startsWith(
							"http"
						)
							? `url(${conference?.customizations?.foyer?.leftSideBackground})`
							: conference?.customizations?.foyer?.leftSideBackground
					}}
				>
					<EventItemHeader conference={conference} />

					{conference?.customizations?.foyer?.scheduleLink && (
						<div className="workspace-aside__list">
							<div className="workspace-aside__list-header">Menù</div>
							<div className="workspace-aside__list-body">
								<span
									onClick={() =>
										window.open(
											conference?.customizations?.foyer?.scheduleLink?.startsWith(
												"http"
											)
												? conference?.customizations?.foyer?.scheduleLink
												: `http://${conference?.customizations?.foyer?.scheduleLink}`,
											"_blank"
										)
									}
								>
									Programma <Icon type="chevron-right" />
								</span>
								<span
									onClick={() =>
										window.open(
											"https://www.lapadigitale.it/guida-piattaforma/",
											"_blank"
										)
									}
								>
									Guida all'utilizzo <Icon type="chevron-right" />
								</span>
								<span
									onClick={() =>
										window.open(
											"https://supertour360.com/360/maggioli/",
											"_blank"
										)
									}
								>
									Coffee break <Icon type="chevron-right" />
								</span>
								<span
									onClick={() =>
										window.open(
											"https://www.lapadigitale.it/privacy-piattaforma/",
											"_blank"
										)
									}
								>
									Privacy policy e termini di utilizzo <Icon type="chevron-right" />
								</span>
							</div>
						</div>
					)}

					{rooms?.length > 0 && (
						<div className="workspace-aside__list">
							<div className="workspace-aside__list-header">Rooms</div>
							<div className="workspace-aside__list-body">
								{rooms?.map((room: any) => (
									<span onClick={() => goToRoom(room)}>
										{room.name} <Icon type="chevron-right" />
									</span>
								))}
							</div>
						</div>
					)}

					{/*
					<div className="workspace-aside__list">
						<div className="workspace-aside__list-header">Participants</div>
						<div className="workspace-aside__list-body">
							<span onClick={() => history.push("/list-users")}>
								List Users <Icon type="chevron-right" />
							</span>
						</div>
					</div>
					*/}

					{stands?.length > 0 && (
						<div className="workspace-aside__list">
							<div className="workspace-aside__list-header">Stands</div>
							<div className="workspace-aside__list-body">
								{[
									stands?.find(
										(item: any) => item.username === appContext.username
									),
									...stands?.filter(
										(item: any) => item.username !== appContext.username
									)
								].map(
									(stand: any) =>
										stand && (
											<span onClick={() => goToStand(stand)}>
												{stand.title} <Icon type="chevron-right" />
											</span>
										)
								)}
							</div>
						</div>
					)}
				</div>

				<div className="workspace-content">
					<div
						className="stub"
						style={{
							background: conference?.customizations?.foyer?.bodyBackground?.startsWith(
								"http"
							)
								? `url(${conference?.customizations?.foyer?.bodyBackground})`
								: conference?.customizations?.foyer?.bodyBackground
						}}
					>
						{/*
							<div className="stub-shape"></div>
							<div className="stub-text">Click on an item to view more</div>
						*/}
					</div>
				</div>
			</div>
		</div>
	)
}
