import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Badge, message, Modal } from "antd"
import Tooltip from "antd/lib/tooltip"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import screenRecordingTutorialChromeImg from "../../assets/img/screen-recording-tutorial-chrome.jpg"
import { AppContext } from "../../context/AppContext"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import {
	createTicketRequest,
	generateShareableRoomLink
} from "../../remote/http"
import { usernameToProfilePicUrl } from "../../remote/images"
import {
	socketJoinChatConversationsUpdates,
	socketUnregisterChatConversationsUpdates
} from "../../remote/socket"
import { Log } from "../../utils/logging"
import { isNullOrEmpty } from "../../utils/string"
import Icon from "../icon/Icon"
import EditAccountModal from "../modal-account/EditAccountModal"
import AddRemoveCoHostModal from "../modal-add-remove-co-host/AddRemoveCoHostModal"
import "./styles/navbar.scss"

interface Props {
	onBack?: () => void
	onAction?: (action: string) => void
	title?: string
	background?: string
	hiddenIcons?: string[]
	videoSource?: string
	whiteboardLink?: string
}

const SCREEN_RECORDING_MIME_TYPE = "video/webm; codecs=vp8,opus"

const isScreenRecordingSupportedByBrowser =
	window.MediaRecorder &&
	window.MediaRecorder.isTypeSupported &&
	window.MediaRecorder.isTypeSupported(SCREEN_RECORDING_MIME_TYPE) &&
	window.AudioContext

let screenRecordingStream: any = null
let hasShownScreenRecordingModal = false

export default (props: Props) => {
	const [appContext] = useContext(AppContext)

	const params: any = useParams()

	const accountOverlayRef = useRef<any>(null)
	const accountIconRef = useRef<any>(null)
	useOnClickOutside([accountOverlayRef, accountIconRef], () =>
		setIsAccountPopupVisible(false)
	)

	const [generatedRoomLinks, setGeneratedRoomLinks] = useState<{
		guestLink: string
		hostLink: string | null
	} | null>(null)

	const [isGeneratingAssistanceRoom, setIsGeneratingAssistanceRoom] = useState<
		boolean
	>(false)

	const [isAccountPopupVisible, setIsAccountPopupVisible] = useState<boolean>(
		false
	)
	const [isAccountModalVisible, setIsAccountModalVisible] = useState<boolean>(
		false
	)

	const [profilePicSrc, setProfilePicSrc] = useState<string | null>(
		usernameToProfilePicUrl(appContext.username!)
	)

	const [isWhiteboardVisible, setIsWhiteboardVisible] = useState<boolean>(false)

	const [isChatBadgeVisible, setIsChatBadgeVisible] = useState<boolean>(false)

	const [isScreenRecording, setIsScreenRecording] = useState<boolean>(false)

	const [
		isAddRemoveCoHostModalVisible,
		setIsAddRemoveCoHostModalVisible
	] = useState<boolean>(false)

	useEffect(() => {
		if (isAccountModalVisible) {
			setIsAccountPopupVisible(false)
		}
	}, [isAccountModalVisible])

	useEffect(() => {
		if (isAddRemoveCoHostModalVisible) {
			setIsAccountPopupVisible(false)
		}
	}, [isAddRemoveCoHostModalVisible])

	useEffect(() => {
		if ((props.hiddenIcons || []).includes("chat-conversations")) {
			return
		}

		socketJoinChatConversationsUpdates(appContext.token!, () => {
			setIsChatBadgeVisible(true)

			// The dot will stay visible as long as the component gets reload,
			// so we can even unregister from the socket now
			socketUnregisterChatConversationsUpdates()
		})

		return () => {
			socketUnregisterChatConversationsUpdates()
		}
	}, [props.hiddenIcons])

	useEffect(() => {
		return () => {
			if (screenRecordingStream) {
				screenRecordingStream.getTracks().forEach((track: any) => track.stop())
			}
		}
	}, [])

	const toggleScreenRecording = async () => {
		if (isScreenRecording) {
			if (screenRecordingStream) {
				screenRecordingStream.getTracks().forEach((track: any) => track.stop())
				setIsScreenRecording(false)
				screenRecordingStream = null
			}
		} else {
			if (!isScreenRecordingSupportedByBrowser) {
				message.error("Your browser doesn't support screen recording")
				return
			}

			const initScreenRecording = async () => {
				const mergeAudioStreams = (desktopStream: any, voiceStream: any) => {
					const context = new AudioContext()
					const destination = context.createMediaStreamDestination()
					let hasDesktop = false
					let hasVoice = false
					if (desktopStream && desktopStream.getAudioTracks().length > 0) {
						// If you don't want to share Audio from the desktop it should still work with just the voice.
						const source1 = context.createMediaStreamSource(desktopStream)
						const desktopGain = context.createGain()
						desktopGain.gain.value = 1
						source1.connect(desktopGain).connect(destination)
						hasDesktop = true
					}

					if (voiceStream && voiceStream.getAudioTracks().length > 0) {
						const source2 = context.createMediaStreamSource(voiceStream)
						const voiceGain = context.createGain()
						voiceGain.gain.value = 1
						source2.connect(voiceGain).connect(destination)
						hasVoice = true
					}

					return hasDesktop || hasVoice
						? destination.stream.getAudioTracks()
						: []
				}

				const navigator = window.navigator as any

				const desktopStream = await navigator.mediaDevices.getDisplayMedia({
					video: {
						cursor: "never"
					},
					audio: {
						echoCancellation: false,
						noiseSuppression: false
					}
				})

				const voiceStream = await navigator.mediaDevices.getUserMedia({
					video: false,
					audio: {
						echoCancellation: false,
						noiseSuppression: false
					}
				})

				const tracks = [
					...desktopStream.getVideoTracks(),
					...mergeAudioStreams(desktopStream, voiceStream)
				]

				screenRecordingStream = new MediaStream(tracks)

				let recorder: MediaRecorder | null = new MediaRecorder(
					screenRecordingStream,
					{
						mimeType: SCREEN_RECORDING_MIME_TYPE
					}
				)

				const chunks: any[] = []

				const onStop = (chunks: any[]) => {
					const completeBlob = new Blob(chunks, { type: chunks[0].type })
					const url = URL.createObjectURL(completeBlob)

					const a: any = document.createElement("a")
					document.body.appendChild(a)
					a.style = "display: none"
					a.download = "Recording.webm"
					a.href = url
					a.click()

					URL.revokeObjectURL(url)
					a.remove()

					voiceStream.getAudioTracks().forEach((audio: any) => audio.stop())
					desktopStream.getAudioTracks().forEach((audio: any) => audio.stop())

					setIsScreenRecording(false)
					screenRecordingStream = null
				}

				recorder.ondataavailable = (e: any) => chunks.push(e.data)
				recorder.onstop = () => onStop(chunks)
				recorder.start()

				screenRecordingStream.getTracks().forEach((track: any) => {
					track.addEventListener("ended", () => {
						voiceStream.getAudioTracks().forEach((audio: any) => audio.stop())

						if (recorder) {
							recorder.stop()
						}

						recorder = null
					})
				})

				setIsScreenRecording(true)
			}

			const isUsingChrome =
				/Chrome/.test(navigator.userAgent) &&
				/Google Inc/.test(navigator.vendor)
			if (hasShownScreenRecordingModal || !isUsingChrome) {
				initScreenRecording()
			} else {
				Modal.confirm({
					title: "Info",
					icon: <ExclamationCircleOutlined />,
					className: "recording-modal",
					content: (
						<div>
							<img src={screenRecordingTutorialChromeImg} width="100%" />
							To record the session, please go to "Tabs" section and select this
							tab, enabling the "Audio" flag. Please note that your audio will
							always be recorded, even after clicking on the muting button.
						</div>
					),
					centered: true,
					okText: "OK",
					cancelText: "Cancel",
					maskClosable: true,
					onOk: () => {
						hasShownScreenRecordingModal = true
						initScreenRecording()
					},
					onCancel: () => {}
				})
			}
		}
	}

	const getHelpOverlay = () => {
		return (
			<>
				<div className="ant-tooltip-header">Assistance</div>
				<ul className="ant-tooltip-menu">
					<li
						className="hoverable"
						onClick={() => {
							setIsGeneratingAssistanceRoom(true)

							createTicketRequest(appContext.token!)
								.then((res) => {
									setIsGeneratingAssistanceRoom(false)

									history.push(`/talk-with-assistance/${res.hash}`)
								})
								.catch(Log.print)
						}}
					>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="account-question" />
							<span>
								{isGeneratingAssistanceRoom
									? "Loading..."
									: "Request Assistance"}
							</span>
						</div>
					</li>
				</ul>
			</>
		)
	}

	const getAccountOverlay = () => (
		<div ref={accountOverlayRef}>
			<div className="ant-tooltip-header">Account</div>
			<ul className="ant-tooltip-menu">
				<li>
					<span>
						You are currently logged in as <b>{appContext.username}</b>.
					</span>
				</li>
				<li
					className="hoverable"
					onClick={() => setIsAccountModalVisible(true)}
				>
					<div className="ant-tooltip-menu-item-container">
						<Icon type="account-edit" />
						<span>Edit Account</span>
					</div>
				</li>
				{appContext.isAdmin && (
					<li className="hoverable" onClick={() => history.push("/admin")}>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="account-group" />
							<span>Admin Panel</span>
						</div>
					</li>
				)}
				{appContext.isSeller && (
					<li className="hoverable" onClick={() => history.push("/edit-stand")}>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="store" />
							<span>Edit Stand</span>
						</div>
					</li>
				)}
				{appContext.isHelpDesk && (
					<li className="hoverable" onClick={() => history.push("/helpdesk")}>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="help" />
							<span>Help Desk</span>
						</div>
					</li>
				)}
				{(appContext.isHelpDesk || appContext.isAdmin) && (
					<li className="hoverable" onClick={() => history.push(`/home`)}>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="home" />
							<span>Home</span>
						</div>
					</li>
				)}
				<li className="hoverable" onClick={() => appContext.logout!()}>
					<div className="ant-tooltip-menu-item-container">
						<Icon type="logout" />
						<span>Logout</span>
					</div>
				</li>
			</ul>
		</div>
	)

	const getShareRoomLinkOverlay = () => (
		<>
			<div className="ant-tooltip-header">
				{params.roomHash ? "Share Room" : "Personal Room"}
			</div>
			<ul className="ant-tooltip-menu">
				<li>
					<span>
						{!generatedRoomLinks
							? "Generating room links..."
							: `We have generated the shareable links of ${
									params.roomHash ? "this" : "your personal"
							  } room.`}
					</span>
				</li>
				{generatedRoomLinks?.guestLink && (
					<li
						className="hoverable"
						onClick={() => {
							message.success("Guest Link copied to clipboard!")
							navigator.clipboard.writeText(generatedRoomLinks.guestLink)
						}}
					>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="content-copy" />
							<span>
								Copy <b>Guest</b> Link
							</span>
						</div>
					</li>
				)}
				{generatedRoomLinks?.hostLink && (
					<li
						className="hoverable"
						onClick={() => {
							message.success("Host Link copied to clipboard!")
							navigator.clipboard.writeText(generatedRoomLinks.hostLink!)
						}}
					>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="content-copy" />
							<span>
								Copy <b>Host</b> Link
							</span>
						</div>
					</li>
				)}
				{generatedRoomLinks && (
					<li
						className="hoverable"
						onClick={() => {
							setGeneratedRoomLinks(null)

							generateShareableRoomLink(appContext.token!, params.roomHash)
								.then(setGeneratedRoomLinks)
								.catch(console.error)
						}}
					>
						<div className="ant-tooltip-menu-item-container">
							<Icon type="autorenew" />
							<span>Generate new links</span>
						</div>
					</li>
				)}
			</ul>
		</>
	)

	const { hiddenIcons = [], onAction = () => {}, videoSource = "" } = props

	return (
		<>
			<div
				className="navbar"
				style={
					!isNullOrEmpty(props.background)
						? {
								background: props.background!.startsWith("http")
									? `url(${props.background})`
									: props.background
						  }
						: {}
				}
			>
				{props.onBack && (
					<div className="navbar__back" onClick={props.onBack}>
						<Icon type="arrow-left" />
					</div>
				)}

				<div className="navbar__title">{props.title}</div>

				<div className="navbar__actions">
					{!hiddenIcons.includes("video-source") && (
						<button
							className="video-source"
							onClick={() => onAction("video-source")}
						>
							{videoSource && videoSource.toUpperCase()}
						</button>
					)}

					{!hiddenIcons.includes("add-remove-co-host") && (
						<div
							className="action add-remove-co-host-action"
							onClick={() =>
								setIsAddRemoveCoHostModalVisible(!isAddRemoveCoHostModalVisible)
							}
						>
							<Icon type="account-supervisor-circle" />
						</div>
					)}

					{!hiddenIcons.includes("help") && (
						<Tooltip
							trigger={["click"]}
							placement="bottomRight"
							title={getHelpOverlay()}
							overlayClassName="navbar-action-tooltip"
							align={{ offset: [0, 25] }}
						>
							<div className="action help-action">
								<Icon type="help-circle-outline" />
							</div>
						</Tooltip>
					)}

					{!hiddenIcons.includes("chat-conversations") && (
						<div
							className="action chat-conversations"
							onClick={() => history.push("/chat-conversations")}
						>
							<Badge className="action" dot={isChatBadgeVisible}>
								<Icon type="forum" />
							</Badge>
						</div>
					)}

					{!hiddenIcons.includes("whiteboard") && props.whiteboardLink && (
						<div
							className="action whiteboard-action"
							onClick={() => setIsWhiteboardVisible(!isWhiteboardVisible)}
						>
							<Icon type={isWhiteboardVisible ? "pencil-remove" : "pencil"} />
						</div>
					)}

					{!hiddenIcons.includes("share-room-link") && appContext.isSeller && (
						<Tooltip
							trigger={["click"]}
							placement="bottomRight"
							title={getShareRoomLinkOverlay()}
							overlayClassName="navbar-action-tooltip"
							align={{ offset: [0, 25] }}
							onVisibleChange={(isVisible: boolean) => {
								if (!isVisible) {
									// Wait for animation to finish
									setTimeout(() => setGeneratedRoomLinks(null), 150)
								} else {
									generateShareableRoomLink(appContext.token!, params.roomHash)
										.then(setGeneratedRoomLinks)
										.catch(console.error)
								}
							}}
						>
							<div className="action help-action">
								<Icon type="google-classroom" />
							</div>
						</Tooltip>
					)}

					{!hiddenIcons.includes("screen-recording") &&
						isScreenRecordingSupportedByBrowser &&
						(window.location.href.includes("/webinar/") ||
							window.location.href.includes("/networking/")) && (
							<div className="action" onClick={toggleScreenRecording}>
								<Icon type={!isScreenRecording ? "video" : "video-off"} />
							</div>
						)}

					{!hiddenIcons.includes("chat") && (
						<div
							className="action notifications-action chat-icon"
							onClick={(_) => onAction("chat")}
						>
							<Icon type="message-text-outline" />
						</div>
					)}

					<Tooltip
						placement="bottomRight"
						visible={isAccountPopupVisible}
						title={getAccountOverlay()}
						overlayClassName="navbar-action-tooltip"
						align={{ offset: [0, 25] }}
					>
						<div
							ref={accountIconRef}
							className="action help-action"
							onClick={() => setIsAccountPopupVisible(!isAccountPopupVisible)}
						>
							<img src={profilePicSrc || ""} className="action-profile-image" />
						</div>
					</Tooltip>
				</div>
			</div>

			{props.whiteboardLink && (
				<iframe
					className="whiteboard"
					scrolling="no"
					frameBorder="0"
					marginHeight={0}
					marginWidth={0}
					style={{
						opacity: isWhiteboardVisible ? 1 : 0,
						pointerEvents: isWhiteboardVisible ? "unset" : "none"
					}}
					src={props.whiteboardLink}
				/>
			)}

			<EditAccountModal
				onCancel={() => setIsAccountModalVisible(false)}
				okButtonColor={props.background}
				visible={isAccountModalVisible}
				onOk={() => {
					// Avoid browser cache when reloading the image
					setProfilePicSrc(
						`${usernameToProfilePicUrl(
							appContext.username!
						)}?random=${Math.random()}`
					)
				}}
			/>

			{params.roomHash && (
				<AddRemoveCoHostModal
					roomHash={params.roomHash}
					visible={isAddRemoveCoHostModalVisible}
					onCancel={() => setIsAddRemoveCoHostModalVisible(false)}
				/>
			)}
		</>
	)
}
