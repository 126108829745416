import { Button, Calendar } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { history } from "../../App"
import { AppContext } from "../../context/AppContext"
import { fetchReservations } from "../../remote/http"
import ReservationModal from "../../components/modal-reservation/ReservationModal"
import Navbar from "../../components/navbar/Navbar"
import { useParams } from "react-router-dom"
import "./styles/agenda.scss"

export default function AgendaContainer() {
	const params: any = useParams()

	const [appContext] = useContext(AppContext)

	const [newReservationDate, setNewReservationDate] = useState<Date | null>(
		null
	)
	const [reservations, setReservations] = useState<any | null>(null)

	const fetchData = () => {
		setReservations(null)

		fetchReservations(appContext.token!, params.username)
			.then(setReservations)
			.catch((err) => {
				alert(`Error: ${err}`)
				history.goBack()
			})
	}

	useEffect(() => window.scrollTo(0, 0), [])

	useEffect(() => fetchData(), [params.username])

	return (
		<div className="agenda">
			<Navbar
				title={`${params.username}'s Agenda`}
				onBack={() => history.goBack()}
				background={appContext.accentColor}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"whiteboard"
				]}
			/>

			<div className="content">
				<Button
					onClick={fetchData}
					type="primary"
					loading={reservations == null}
					style={{ marginTop: 32, marginBottom: 16 }}
				>
					{reservations == null ? "Loading..." : "Refresh"}
				</Button>

				<Calendar
					mode="month"
					dateCellRender={(value) => {
						if (!reservations) {
							return null
						}

						const cellValueDate = value.toDate()
						const keyDate = `${cellValueDate.getFullYear()}-${
							cellValueDate.getMonth() + 1
						}-${cellValueDate.getDate()}`

						const numOfReservationsOnCellDay = reservations[keyDate]
						if (!numOfReservationsOnCellDay) {
							return null
						}

						return (
							<span>
								<b>{numOfReservationsOnCellDay}</b> reservation
								{numOfReservationsOnCellDay == 1 ? "" : "s"}
							</span>
						)
					}}
					onSelect={(date) => {
						// Avoid underneath click when selecting a month / year from the dropdown
						const calendarDropDownsOpen =
							document.querySelectorAll(".ant-select-open").length > 0
						if (!calendarDropDownsOpen) {
							setNewReservationDate(date.toDate())
						}
					}}
				/>

				<ReservationModal
					username={params.username}
					visible={newReservationDate !== null}
					date={newReservationDate!}
					onCancel={() => {
						setNewReservationDate(null)
						setTimeout(() => window.scrollTo(0, 0), 1)
					}}
				/>
			</div>
		</div>
	)
}
