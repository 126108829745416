import { Button, notification } from "antd"
import "antd/dist/antd.css"
import { createHashHistory } from "history"
import React, { useContext, useEffect } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {
	HashRouter as Router,
	Redirect,
	Route,
	Switch,
	useParams
} from "react-router-dom"
import "./assets/scss/app.scss"
import AcceptReservationContainer from "./containers/accept-reservation/AcceptReservationContainer"
import AdminContainer from "./containers/admin/AdminContainer"
import AdminCreateConferenceContainer from "./containers/admin/AdminCreateConferenceContainer"
import AdminCreateRoomContainer from "./containers/admin/AdminCreateRoomContainer"
import AdminManageConferenceContainer from "./containers/admin/AdminManageConferenceContainer"
import AdminManageRoomsContainer from "./containers/admin/AdminManageRoomsContainer"
import AdminManageUsersContainer from "./containers/admin/AdminManageUsersContainer"
import AgendaContainer from "./containers/agenda/AgendaContainer"
import ForgotPasswordContainer from "./containers/auth/ForgotPasswordContainer"
import ForgotPasswordUpdateContainer from "./containers/auth/ForgotPasswordUpdateContainer"
import LoginContainer from "./containers/auth/LoginContainer"
import RegisterContainer from "./containers/auth/RegisterContainer"
import VerifyAccountContainer from "./containers/auth/VerifyAccountContainer"
import ChatConversationsContainer from "./containers/chat-conversations/ChatConversationsContainer"
import ChatContainer from "./containers/chat/ChatContainer"
import FoyerContainer from "./containers/foyer/FoyerContainer"
import HelpDeskTicketsListContainer from "./containers/helpdesk/HelpDeskTicketsList"
import ListUsersContainer from "./containers/list-users/ListUsersContainer"
import AgoraRoomContainer from "./containers/room/AgoraRoomContainer"
import MainRoomContainer from "./containers/room/MainRoomContainer"
import NetworkingRoomContainer from "./containers/room/NetworkingRoomContainer"
import StandEditContainer from "./containers/stand-edit/StandEditContainer"
import StandContainer from "./containers/stand/StandContainer"
import { AppContext } from "./context/AppContext"
import { fetchMyUserInfo } from "./remote/http"
import {
	socketJoinUserUpdates,
	socketNotifyConnection,
	socketUnregisterUserUpdates
} from "./remote/socket"
import { Log } from "./utils/logging"

export const history = createHashHistory()

function withAuth(Component: any): any {
	return (routeProps: any) =>
		localStorage.token ? <Component {...routeProps} /> : <Redirect to={"/"} />
}

export default function App() {
	const [appContext] = useContext(AppContext)

	useEffect(() => {
		const ping = () => {
			const token = appContext.token
			if (token !== undefined && token !== "") {
				socketNotifyConnection(token)
			}
		}

		// Ping server so that it knows the user is online
		const interval = setInterval(ping, 5 * 1000)

		ping()

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (!appContext.token) {
			socketUnregisterUserUpdates()
			return
		}

		Log.print("Registering user updates...")

		socketJoinUserUpdates(appContext.token, (data: any) => {
			Log.print("Received user update event")

			if (data.possiblyForceRefresh) {
				const webinarRoomHash = data.updatedElement.includes("webinar-")
					? data.updatedElement.split("webinar-")[1]
					: null

				if (
					webinarRoomHash &&
					window.location.href.includes(`/webinar/${webinarRoomHash}`)
				) {
					window.location.reload()
					return
				} else if (data.updatedElement == "admin") {
					appContext.logout && appContext.logout()
					return
				}
			}

			fetchMyUserInfo(appContext.token!)
				.then(() => {
					Log.print("User can still log in")

					notification.open({
						message: "Warning",
						description:
							"Your user settings have been updated. Click the button below to refresh the page.",
						placement: "bottomLeft",
						btn: (
							<Button
								type="primary"
								size="small"
								onClick={() => window.location.reload()}
							>
								Reload
							</Button>
						)
					})
				})
				.catch(() => {
					Log.print("User cannot login, going to login screen")
					appContext.logout!()
				})
		})

		return () => {
			socketUnregisterUserUpdates()
		}
	}, [appContext.token])

	return (
		<Router {...{ history }}>
			<Switch>
				<Route exact path="/" children={<Redirect to="/login" />} />
				<Route exact path="/login" component={LoginContainer} />
				<Route path="/register" component={RegisterContainer} />
				<Route
					exact
					path="/forgot-password"
					component={ForgotPasswordContainer}
				/>
				<Route
					path="/forgot-password/update/:jwt"
					component={ForgotPasswordUpdateContainer}
				/>
				<Route path="/verify-account/:jwt" component={VerifyAccountContainer} />
				<Route exact path="/home" render={withAuth(FoyerContainer)} />
				<Route
					exact
					path="/helpdesk"
					render={withAuth(HelpDeskTicketsListContainer)}
				/>
				<Route path="/main/:roomHash" render={withAuth(MainRoomContainer)} />
				<Route
					path={["/webinar/:roomHash", "/plenary-host/:roomHash"]}
					render={withAuth(AgoraRoomContainer)}
				/>
				<Route
					exact
					path={["/networking/:roomHash", "/talk-with-assistance/:roomHash"]}
					render={withAuth(NetworkingRoomContainer)}
				/>
				<Route
					exact
					path="/stand/:username"
					render={withAuth(StandContainer)}
				/>
				<Route exact path="/edit-stand" render={withAuth(StandEditContainer)} />
				<Route exact path="/list-users" render={withAuth(ListUsersContainer)} />
				<Route
					exact
					path="/agenda/:username"
					render={withAuth(AgendaContainer)}
				/>
				<Route
					path="/reservation/:reservationId/accept/:jwt"
					component={AcceptReservationContainer}
				/>
				<Route
					path="/chat-conversations"
					render={withAuth(ChatConversationsContainer)}
				/>
				<Route exact path="/chat/:username" render={withAuth(ChatContainer)} />
				<Route exact path="/admin" render={withAuth(AdminContainer)} />
				<Route
					path="/admin/create-conference"
					render={withAuth(AdminCreateConferenceContainer)}
				/>
				<Route
					path="/admin/manage-conference"
					component={withAuth(AdminManageConferenceContainer)}
				/>
				<Route
					path="/admin/manage-users"
					component={withAuth(AdminManageUsersContainer)}
				/>
				<Route
					path="/admin/manage-rooms"
					component={withAuth(AdminManageRoomsContainer)}
				/>
				<Route
					path="/admin/create-room"
					component={withAuth(AdminCreateRoomContainer)}
				/>
			</Switch>
		</Router>
	)
}
