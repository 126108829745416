import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import React, { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { history } from "../../App"
import ContentLoader from "../../components/loader/ContentLoader"
import { AppContext } from "../../context/AppContext"
import { fetchLoginCustomizations, login } from "../../remote/http"
import { Log } from "../../utils/logging"
import { isNullOrEmpty } from "../../utils/string"
import "./styles/login.scss"

export default function LoginContainer(props: any) {
	const [appContext, setAppContext] = useContext(AppContext)

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [customizations, setCustomizations] = useState<any | null>(null)

	useEffect(() => {
		if (appContext.token) {
			history.push(`/home`)
		} else {
			fetchLoginCustomizations()
				.then(setCustomizations)
				.catch((err) => {
					setCustomizations({})
				})
		}
	}, [])

	const onFinish = async (values: any) => {
		setLoading(true)

		try {
			const loginResponse: any = await login(appContext, setAppContext, values)

			if (loginResponse.isHelpDesk) {
				history.push(`/helpdesk`)
			} else if (loginResponse.isAdmin) {
				history.push(`/admin`)
			} else if (loginResponse.isSeller && !loginResponse.isFoyerVisible) {
				history.push("/edit-stand")
			} else {
				if (!loginResponse.conferenceId) {
					alert("Conference is not started yet!")

					setLoading(false)

					appContext.logout!()
				} else {
					history.push(`/home`)
				}
			}
		} catch (e) {
			Log.print("Error!", e)
			alert(`Error: ${e}`)
			setLoading(false)
		}
	}

	const loginBackgroundStyle = !isNullOrEmpty(customizations?.bodyBackground)
		? {
				background: customizations.bodyBackground.startsWith("http")
					? `url(${customizations.bodyBackground})`
					: customizations.bodyBackground
		  }
		: {}
	const loginCardStyle = !isNullOrEmpty(customizations?.cardBackground)
		? {
				background: customizations.cardBackground.startsWith("http")
					? `url(${customizations.cardBackground})`
					: customizations.cardBackground
		  }
		: {}
	const loginLeftSideBackgroundStyle = !isNullOrEmpty(
		customizations?.leftSideBackground
	)
		? {
				background: customizations.leftSideBackground.startsWith("http")
					? `url(${customizations.leftSideBackground})`
					: customizations.leftSideBackground
		  }
		: {}

	return !customizations ? (
		<ContentLoader />
	) : (
		<>
			<div className="login-background-layer" style={loginBackgroundStyle} />
			<div className="login-wrapper">
				<div className="auth login" style={loginCardStyle}>
					{!isNullOrEmpty(customizations?.leftSideBackground) && (
						<>
							<div
								className="login-event"
								style={loginLeftSideBackgroundStyle}
							></div>
							<div className="login-separator"></div>
						</>
					)}
					<div className="login-form">
						<Form className="form" onFinish={onFinish}>
							<h2>Log In</h2>
							<Form.Item
								name="email"
								label="Email / Username"
								rules={[{ required: true, message: "Please input your email" }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="password"
								label="Password"
								rules={[
									{ required: true, message: "Please input your password" }
								]}
							>
								<Input type="password" />
							</Form.Item>
							<Form.Item
								name="link"
								style={{ display: "block", marginTop: -12 }}
							>
								<NavLink to="forgot-password">Forgot password?</NavLink>
							</Form.Item>
							<Form.Item name="submit">
								<Button
									className="fullwidth"
									type="primary"
									htmlType="submit"
									disabled={
										!!form
											.getFieldsError()
											.filter(({ errors }) => errors.length).length
									}
									loading={loading}
								>
									Log In
								</Button>
							</Form.Item>
							<Form.Item name="link">
								<NavLink to="register">Don't have an account?</NavLink>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</>
	)
}
