export function arraysEqual(firstArray: Array<any>, secondArray: Array<any>) {
	if (
		!Array.isArray(firstArray) ||
		!Array.isArray(secondArray) ||
		firstArray.length !== secondArray.length
	) {
		return false
	}

	var arr1 = firstArray.concat().sort()
	var arr2 = secondArray.concat().sort()

	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false
		}
	}

	return true
}

export function deepCopy(src: any) {
	let target: any = Array.isArray(src) ? [] : {}
	for (let key in src) {
		let v = src[key]
		if (v) {
			if (typeof v === "object") {
				target[key] = deepCopy(v)
			} else {
				target[key] = v
			}
		} else {
			target[key] = v
		}
	}

	return target
}
