import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import EmbedLink from "../../components/embed-link/EmbedLink"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { joinRoom } from "../../remote/http"
import {
	socketJoinRoom,
	socketUnregisterRoomUpdates
} from "../../remote/socket"
import "./styles/webinar.scss"

interface JoinData {
	roomName: string
	isRoomHost: boolean
	isRoomCoHost: boolean
	customizations: any
	embedLinks: Array<string> | null
	whiteboardLink: string
}

export default () => {
	const [appContext] = useContext(AppContext)

	const params: any = useParams()

	const [joinData, setJoinData] = useState<JoinData>()
	const [isEmbedLoaded, setIsEmbedLoaded] = useState<boolean>(false)

	useEffect(() => {
		const roomHash = params.roomHash
		joinRoom(appContext.token!, roomHash)
			.then((res: any) => {
				setJoinData(res)
			})
			.catch((err: any) => {
				if (err == "TICKET ALREADY HANDLED") {
					alert("Ticket already took in charge by someone else")
					history.push("/helpdesk")
				} else {
					alert("Error: " + err)
				}
			})

		socketJoinRoom(appContext.token!, roomHash, () => {
			Modal.confirm({
				title: "Warning",
				icon: <ExclamationCircleOutlined />,
				content:
					"This room got updated. Click the OK button below to refresh the page.",
				centered: true,
				maskClosable: false,
				okText: "OK",
				cancelText: "Cancel",
				onOk: () => window.location.reload(),
				onCancel() {}
			})
		})

		return () => {
			socketUnregisterRoomUpdates()
		}
	}, [])

	const classes = ["room", "webinar"].filter(Boolean).join(" ")

	return (
		<>
			{!isEmbedLoaded && <ContentLoader />}
			<div
				className="room-background-layer"
				style={
					joinData?.customizations?.bodyBackground
						? {
								background: joinData?.customizations?.bodyBackground.startsWith(
									"http"
								)
									? `url(${joinData?.customizations?.bodyBackground})`
									: joinData?.customizations?.bodyBackground
						  }
						: {}
				}
			/>

			<div className={classes} style={{ overflow: "hidden" }}>
				<div className="room-workspace">
					<Navbar
						onBack={() => history.push("/home")}
						hiddenIcons={[
							"chat-conversations",
							"notifications",
							"video-source",
							"chat",
							"help",
							"add-remove-co-host"
						]
							.concat(
								!joinData?.embedLinks ||
									!joinData?.embedLinks[0].includes("jitsi") ||
									!joinData.embedLinks ||
									joinData?.roomName == "Assistance"
									? ["share-room-link"]
									: []
							)
							.concat(
								!(joinData?.customizations?.hosts || []).includes(
									appContext.username
								)
									? ["screen-recording"]
									: []
							)}
						title={joinData?.roomName || "Assistance Room"}
						background={
							joinData?.customizations?.headerBackground ||
							appContext?.accentColor
						}
						whiteboardLink={joinData?.whiteboardLink}
					/>
					<div className="workspace" style={{ display: "block" }}>
						{joinData?.embedLinks && (
							<EmbedLink
								src={joinData?.embedLinks[0]}
								onLoad={() => setIsEmbedLoaded(true)}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
