import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import Icon from "../../components/icon/Icon"
import { acceptReservationRequest } from "../../remote/http"
import "./styles/accept-reservation.scss"

export default function AcceptReservationContainer(props: any) {
    const params: any = useParams()

    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const token = params.jwt
        const reservationId = params.reservationId

        acceptReservationRequest(token, reservationId)
            .then(() => {
                setLoading(false)
            })
            .catch(() => {
                window.alert("Error while accepting reservation request")
            })
    }, [])

    return (
        <div className="accept-reservation">
            {loading
                ? <div className="message">Loading...</div>
                :
                <>
                    <div className="message">
                        <b>Done!</b><br />
                            You have successfully accepted the reservation request.
                            We sent you an email with all the information you'll need to access to the meeting.
                        </div>
                </>
            }
        </div>
    )
}
