import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import message from "antd/lib/message"
import React, { useEffect, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import { history } from "../../App"
import ContentLoader from "../../components/loader/ContentLoader"
import {
	fetchLoginCustomizations,
	forgotPasswordUpdate
} from "../../remote/http"
import { Log } from "../../utils/logging"
import { isNullOrEmpty } from "../../utils/string"
import "./styles/login.scss"

export default function ForgotPasswordUpdateContainer(props: any) {
	const params: any = useParams()

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [customizations, setCustomizations] = useState<any | null>(null)

	useEffect(() => {
		fetchLoginCustomizations()
			.then(setCustomizations)
			.catch((err) => {
				setCustomizations({})
			})
	}, [])

	const onFinish = async (values: any) => {
		setLoading(true)

		try {
			await forgotPasswordUpdate(params.jwt, values)

			alert("Successfully updated password!")

			history.push("/")
		} catch (e) {
			Log.print("Error!", e)

			const errorMsg = e?.conference == null ? "Error" : e.message
			message.error(errorMsg)

			setLoading(false)
		}
	}

	const loginBackgroundStyle = !isNullOrEmpty(customizations?.bodyBackground)
		? {
				background: customizations.bodyBackground.startsWith("http")
					? `url(${customizations.bodyBackground})`
					: customizations.bodyBackground
		  }
		: {}
	const loginCardStyle = !isNullOrEmpty(customizations?.cardBackground)
		? {
				background: customizations.cardBackground.startsWith("http")
					? `url(${customizations.cardBackground})`
					: customizations.cardBackground
		  }
		: {}

	return !customizations ? (
		<ContentLoader />
	) : (
		<>
			<div className="login-background-layer" style={loginBackgroundStyle} />
			<div className="login-wrapper">
				<div className="auth login" style={loginCardStyle}>
					<div className="login-form">
						<Form className="form" onFinish={onFinish}>
							<h2>Forgot Password - Update</h2>
							<Form.Item
								name="newPassword"
								label="Password"
								rules={[
									{ required: true, message: "Please input your password" }
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item name="submit">
								<Button
									type="primary"
									htmlType="submit"
									disabled={
										!!form
											.getFieldsError()
											.filter(({ errors }) => errors.length).length
									}
									loading={loading}
								>
									Update password
								</Button>
							</Form.Item>
							<Form.Item name="link">
								<NavLink to="/login">Login</NavLink>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</>
	)
}
