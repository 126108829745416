import { Select } from 'antd'
import React, { useState } from 'react'

const { Option } = Select

interface Props {
    values: Array<any>
    defaultValues: Array<any>
    onChange: any
}

export default function StatefulMultiSelect(props: Props) {
    const [selectedValues, setSelectedValues] = useState(props.defaultValues)

    const onChange = (newValues: any) => {
        if (newValues.includes("all")) {
            newValues = props.values
            newValues.filter((value: any) => value !== "all")
        }

        setSelectedValues(newValues)

        props.onChange && props.onChange(newValues)
    }

    return (
        <Select
            mode="multiple"
            style={{ width: '200px' }}
            placeholder="Select rooms"
            defaultValue={props.defaultValues}
            value={selectedValues}
            onChange={onChange}>
            <Option key={"all"} value={"all"}>Select All</Option>
            {props.values?.map((room: any) => (
                <Option key={`${room}-${Math.random()}`} value={room}>{room}</Option>
            ))}
        </Select>
    )
}