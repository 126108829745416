const DEBUG = true

export const Log = {
	print: (...args: any) => {
		if (!DEBUG) {
			return
		}

		console.log(...args)
	}
}
