import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import message from "antd/lib/message"
import React, { useContext, useState } from "react"
import { history } from "../../App"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { adminCreateConference } from "../../remote/http"
import { Log } from "../../utils/logging"
import "./styles/admin.scss"

export default function AdminCreateConferenceContainer() {
	const [appContext] = useContext(AppContext)

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const onFinish = async (values: any) => {
		if (
			!window.confirm(
				"Do you want to proceed?\nThis will delete the old conference.\nIf you are creating users, you'll need to login again."
			)
		) {
			return
		}

		setLoading(true)

		try {
			await adminCreateConference(appContext.token!, values)

			setLoading(false)

			alert("Done!")
		} catch (e) {
			Log.print("Error!", e)

			const errorMsg = e.toString()
			message.error(errorMsg)

			setLoading(false)
		}
	}

	return (
		<div className="admin">
			<Navbar
				onBack={() => {
					history.goBack()
				}}
				title={"Admin Panel - Create Conference"}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"whiteboard",
					"add-remove-co-host"
				]}
			/>

			<div className="create-conference-form">
				<Form className="form" onFinish={onFinish}>
					<Form.Item
						name="eventTitle"
						label="Event Title"
						rules={[{ required: true, message: "Please fill this input" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="submit" style={{ marginTop: 32 }}>
						<Button
							type="primary"
							htmlType="submit"
							disabled={
								!!form.getFieldsError().filter(({ errors }) => errors.length)
									.length
							}
							loading={loading}
						>
							Create Conference
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}
