import { Card, Col, Row, Upload } from "antd"
import Button from "antd/lib/button"
import Input from "antd/lib/input"
import React, { useContext, useEffect, useRef, useState } from "react"
import { history } from "../../App"
import Icon from "../../components/icon/Icon"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import NewProductModal from "../../components/modal-new-product/NewProductModal"
import { AppContext } from "../../context/AppContext"
import { fetchUserInfo, updateUserInfo } from "../../remote/http"
import {
	standProductToImageUrl,
	usernameToStandHeroImageUrl
} from "../../remote/images"
import { isNullOrEmpty } from "../../utils/string"
import { Stand } from "../stand/StandContainer"
import "./styles/stand-edit.scss"

export default function StandEditContainer() {
	const [appContext] = useContext(AppContext)

	const [loading, setLoading] = useState<boolean>(false)
	const [stand, setStand] = useState<Stand | null>(null)

	const [productToEdit, setProductToEdit] = useState<any>()

	const [newSponsorImageUrl, setNewSponsorImageUrl] = useState<string>("")
	const [newSponsorDestinationUrl, setNewSponsorDestinationUrl] = useState<
		string
	>("")

	const [heroBackgroundBase64, setHeroBackgroundBase64] = useState<any>()

	const newSponsorImageUrlRef = useRef<Input>(null)
	const newSponsorDestinationUrlRef = useRef<Input>(null)

	const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(
		false
	)

	useEffect(() => {
		fetchUserInfo(
			appContext.token!,
			appContext.username!,
			true
		).then((userInfo: any) => setStand(userInfo.stand))
	}, [])

	const heroImageSrc =
		heroBackgroundBase64 || usernameToStandHeroImageUrl(appContext.username!)

	return !stand ? (
		<ContentLoader />
	) : (
		<div className="stand-edit">
			<Navbar
				onBack={() => history.goBack()}
				title={"Edit Stand"}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"add-remove-co-host"
				]}
			/>

			<div className="form-wrapper">
				<label>Title</label>
				<Input
					defaultValue={stand?.title}
					placeholder="Title"
					onChange={(e) => {
						stand.title = e.target.value
						setStand({ ...stand })
					}}
				/>

				<label>Hero Background</label>
				{!isNullOrEmpty(heroImageSrc) && (
					<img src={heroImageSrc} style={{ width: 300, height: "auto" }} />
				)}
				<br />
				<Upload
					accept=".png,.jpg"
					showUploadList={false}
					beforeUpload={(file: any) => {
						const reader = new FileReader()
						reader.onloadend = () => {
							const base64 = reader.result
							setHeroBackgroundBase64(base64)
						}
						reader.readAsDataURL(file)

						return false
					}}
				>
					<Button
						className="fullwidth"
						style={{ color: "white", marginTop: 16, marginBottom: 16 }}
					>
						<Icon type="upload" />
						<span>
							{heroBackgroundBase64
								? "Image selected, ready to upload"
								: "Select new image"}
						</span>
					</Button>
				</Upload>

				<label>Stand 3D (URL)</label>
				<Input
					defaultValue={stand?.stand3dLink}
					placeholder="Stand 3D (URL)"
					onChange={(e) => {
						stand.stand3dLink = e.target.value
						setStand({ ...stand })
					}}
				/>

				<label>Description</label>
				<Input
					defaultValue={stand?.description}
					placeholder="Description"
					onChange={(e) => {
						stand.description = e.target.value
						setStand({ ...stand })
					}}
				/>

				<label>Video Link (YouTube)</label>
				<Input
					defaultValue={stand?.videoLink}
					placeholder="Video Link"
					onChange={(e) => {
						const getYoutubeLinkId = (url: string) => {
							const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
							const match = url.match(regExp)

							return match && match[2].length === 11 ? match[2] : null
						}

						const embedVideoLink = `https://www.youtube.com/embed/${getYoutubeLinkId(
							e.target.value
						)}`
						stand.videoLink = embedVideoLink
						setStand({ ...stand })
					}}
				/>

				<label>Phone</label>
				<Input
					defaultValue={stand?.phone}
					placeholder="Phone"
					onChange={(e) => {
						stand.phone = e.target.value
						setStand({ ...stand })
					}}
				/>

				<label>Location</label>
				<Input
					defaultValue={stand?.location}
					placeholder="Location"
					onChange={(e) => {
						stand.location = e.target.value
						setStand({ ...stand })
					}}
				/>

				<label>Website</label>
				<Input
					defaultValue={stand?.website}
					placeholder="Website"
					onChange={(e) => {
						stand.website = e.target.value
						setStand({ ...stand })
					}}
				/>

				<label style={{ marginTop: 36 }}>Products</label>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="products">
					{stand?.products.map((product: any) => (
						<Col
							className="product"
							lg={{ span: 8 }}
							md={{ span: 12 }}
							xs={{ span: 24 }}
						>
							<Card
								className="gutter-row"
								cover={
									<img
										src={
											product.productImageBase64 ||
											standProductToImageUrl(appContext.username!, product)
										}
									/>
								}
							>
								<Card.Meta
									title={product.name}
									description={
										<>
											{product.salePrice ? (
												<span>
													<del>{product.price}</del> {product.salePrice}
												</span>
											) : (
												product.price
											)}
											{product.destinationUrl ? (
												<Button
													className="fullwidth"
													onClick={() =>
														window.open(
															product.destinationUrl.startsWith("http")
																? product.destinationUrl
																: `http://${product.destinationUrl}`,
															"_blank"
														)
													}
												>
													<span>
														<Icon type="open-in-new" /> View
													</span>
												</Button>
											) : null}

											<Button
												className="fullwidth"
												onClick={() => {
													setProductToEdit({ ...product })
													setIsNewProductModalVisible(true)
												}}
											>
												<span>
													<Icon type="pencil" /> Edit
												</span>
											</Button>

											<Button
												className="fullwidth"
												onClick={() => {
													stand.products = stand.products.filter(
														(p: any) =>
															p.name != product.name ||
															p.price != product.price ||
															p.salePrice != product.salePrice ||
															p.destinationUrl != product.destinationUrl ||
															p.imageUrl != product.imageUrl
													)
													setStand({ ...stand })
												}}
											>
												<span>
													<Icon type="close" /> Remove Item
												</span>
											</Button>
										</>
									}
								/>
							</Card>
						</Col>
					))}

					<Col
						className="product"
						lg={{ span: 8 }}
						md={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Card
							className="gutter-row"
							cover={
								<img src="https://via.placeholder.com/800x600.png?text=%2B" />
							}
						>
							<Card.Meta
								title="Create new product"
								description={
									<>
										Click the button and fill the information to create a new
										product
										<Button
											className="fullwidth"
											onClick={() => {
												setIsNewProductModalVisible(true)
											}}
										>
											Create Product
										</Button>
									</>
								}
							/>
						</Card>
					</Col>
				</Row>

				<label style={{ marginTop: 36 }}>Sponsors</label>
				{stand.sponsors.map((sponsor: any) => (
					<>
						<hr />
						<Input
							disabled
							value={sponsor.imageUrl}
							placeholder="Image (URL)"
						/>
						<Input
							disabled
							value={sponsor.destinationUrl}
							placeholder="Destination (URL)"
						/>
						<Button
							style={{ marginBottom: 16 }}
							onClick={() => {
								stand.sponsors = stand.sponsors.filter((s) => s !== sponsor)

								setStand({ ...stand })
							}}
						>
							Remove
						</Button>
					</>
				))}
				<Input
					ref={newSponsorImageUrlRef}
					placeholder="Image (URL)"
					onChange={(e) => setNewSponsorImageUrl(e.target.value)}
				/>
				<Input
					ref={newSponsorDestinationUrlRef}
					placeholder="Destination (URL)"
					onChange={(e) => setNewSponsorDestinationUrl(e.target.value)}
				/>
				<Button
					onClick={() => {
						if (
							isNullOrEmpty(newSponsorImageUrl) ||
							isNullOrEmpty(newSponsorDestinationUrl)
						) {
							return window.alert("Please fill the fields first")
						}

						stand.sponsors.push({
							imageUrl: newSponsorImageUrl,
							destinationUrl: newSponsorDestinationUrl
						})
						setStand({ ...stand })

						setNewSponsorImageUrl("")
						setNewSponsorDestinationUrl("")

						newSponsorImageUrlRef.current?.setValue("")
						newSponsorDestinationUrlRef.current?.setValue("")
					}}
				>
					Add new
				</Button>

				<br />

				<Button
					className="update-btn"
					loading={loading}
					onClick={async () => {
						setLoading(true)

						const newStand: any = stand
						newStand.heroBackgroundBase64 = heroBackgroundBase64

						try {
							await updateUserInfo(appContext.token!, {
								email: null,
								name: null,
								surname: null,
								website: null,
								profilePicBase64: null,
								stand
							})
						} catch (err) {
							alert(
								`Error: ${typeof err == "object" ? JSON.stringify(err) : err}`
							)
							return
						}

						alert("Done!")

						setLoading(false)
					}}
				>
					Update Stand
				</Button>

				<Button
					className="view-stand-btn"
					onClick={() => {
						history.push(`/stand/${appContext.username}`)
					}}
				>
					View Stand
				</Button>
			</div>

			<NewProductModal
				onConfirm={(
					name,
					price,
					salePrice,
					productImageBase64,
					destinationUrl
				) => {
					const newProduct = {
						name,
						price,
						salePrice,
						productImageBase64,
						imageUrl: productImageBase64,
						destinationUrl
					}

					if (productToEdit) {
						stand.products = stand.products.map((oldProduct) =>
							oldProduct.name == productToEdit.name ? newProduct : oldProduct
						)

						setProductToEdit(undefined)
					} else {
						stand.products.push(newProduct)
					}

					setStand({ ...stand })
				}}
				onCancel={() => {
					setIsNewProductModalVisible(false)
					setProductToEdit(undefined)
				}}
				productToEdit={productToEdit}
				visible={isNewProductModalVisible}
			/>
		</div>
	)
}
