import io from "socket.io-client"
import { BASE_PATH } from "../config"
import { Log } from "../utils/logging"

const socket = io.connect(BASE_PATH)

export function socketNotifyConnection(token: string) {
	socket.emit("notify-online-status", {
		token
	})
}

export function socketRoomJoin(
	token: string,
	roomHash: string | null = null,
	username: string | null = null,
	callback: (message: any) => void
) {
	const payload: any = {
		token,
		callback
	}

	if (roomHash) {
		payload.roomHash = roomHash
	} else if (username) {
		payload.username = username
	}

	socket.emit("room-join", payload)

	socket.on("chat-message-received", (data: any) => {
		callback(data)
	})
}

export async function socketSendChatMessage(
	message: string,
	token: string,
	roomHash: string | null = null,
	username: string | null = null
) {
	const payload: any = {
		message,
		token
	}

	if (roomHash) {
		payload.roomHash = roomHash
	} else if (username) {
		payload.username = username
	}

	socket.emit("chat-message-send", payload)
}

export async function socketJoinPlenaryDirection(
	username: string,
	token: string,
	roomHash: string,
	onDirectionUpdate: (newStatus: string) => void
) {
	socket.emit("join-direction", {
		token,
		roomHash
	})

	socket.on("on-direction-update", (data: any) => {
		if (data.Data == "prev" || data.Data == "next") {
			// Don't show my actions
			return
		}

		if (data.Name == username) {
			onDirectionUpdate(data.Data)
		} else {
			onDirectionUpdate("Remote Control: OFF")
		}
	})
}

export async function socketSendPlenaryDirectionEvent(
	username: string,
	token: string,
	roomHash: string,
	Data: string
) {
	socket.emit("send-direction-event", {
		token,
		roomHash,
		Name: username,
		Data
	})
}

export function socketJoinUserUpdates(
	token: string,
	onUserUpdate: (data: any) => void
) {
	socket.emit("register-user-updates", {
		token
	})

	socket.on("on-user-update", onUserUpdate)
}

export function socketJoinHomeUpdates(token: string, onHomeUpdate: () => void) {
	socket.emit("register-home-updates", {
		token
	})

	socket.on("on-home-update", onHomeUpdate)
}

export function socketJoinChatConversationsUpdates(
	token: string,
	onUpdate: (data: any) => void
) {
	socket.emit("register-chat-conversations-updates", {
		token
	})

	socket.on("on-chat-conversations-update", (data: any) => {
		onUpdate(data)
	})
}

export function socketJoinRoom(
	token: string,
	roomHash: string,
	onRoomUpdate: (data: any) => void
) {
	socket.emit("register-room-updates", {
		token,
		roomHash
	})

	socket.on("on-room-update", onRoomUpdate)
}

export function socketJoinHelpDesk(
	token: string,
	onHelpDeskUpdate: () => void
) {
	socket.emit("register-helpdesk-updates", {
		token
	})

	socket.on("on-helpdesk-update", onHelpDeskUpdate)
}

export function socketJoinAdminDashboard(
	token: string,
	callback: (
		newOnlineUsers: Array<string>,
		newOfflineUsers: Array<string>
	) => void
) {
	socket.emit("register-admin-dashboard-updates", {
		token
	})

	socket.on("update-online-users", (data: any) => {
		callback(data.newOnlineUsers, data.newOfflineUsers)
	})
}

export function socketSendLogoutEvent(token: string) {
	socket.emit("logout", { token })
}

export function socketUnregisterRoomJoin(
	token: string,
	roomHash: string | null = null,
	username: string | null = null
) {
	const payload: any = {
		token,
		roomHash
	}

	if (roomHash) {
		payload.roomHash = roomHash
	} else if (username) {
		payload.username = username
	}

	Log.print("Unregistering from 'chat-message-received'...")

	socket.emit("room-leave", payload)
	socket.off("chat-message-received")
}

export function socketUnregisterAdminDashboard() {
	Log.print("Unregistering from 'update-online-users'...")
	socket.off("update-online-users")
}

export function socketUnregisterUserUpdates() {
	Log.print("Unregistering from 'on-user-update'...")
	socket.off("on-user-update")
}

export function socketUnregisterPlenaryDirection() {
	Log.print("Unregistering from 'on-direction-update'...")
	socket.off("on-direction-update")
}

export function socketUnregisterHomeUpdates() {
	Log.print("Unregistering from 'on-home-update'...")
	socket.off("on-home-update")
}

export function socketUnregisterChatConversationsUpdates() {
	Log.print("Unregistering from 'on-chat-conversations-update'...")
	socket.off("on-chat-conversations-update")
}

export function socketUnregisterHelpDeskUpdates() {
	Log.print("Unregistering from 'on-helpdesk-update'...")
	socket.off("on-helpdesk-update")
}

export function socketUnregisterRoomUpdates() {
	Log.print("Unregistering from 'on-room-update'...")
	socket.off("on-room-update")
}
