import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import Chat from "../../components/chat/Chat"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import "./styles/chat-container.scss"

export default function ChatContainer() {
	const [appContext] = useContext(AppContext)

	const params: any = useParams()

	return (
		<div className="chat-container">
			<Navbar
				title={`${params.username}'s chat`}
				onBack={() => history.goBack()}
				background={appContext.accentColor}
				hiddenIcons={[
					"chat-conversations",
					"chat",
					"video-source",
					"help",
					"share-room-link",
					"whiteboard",
					"add-remove-co-host"
				]}
			/>

			<Chat fullWidth username={params.username} />
		</div>
	)
}
