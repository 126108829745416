import { Avatar, List } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { history } from "../../App"
import Chat from "../../components/chat/Chat"
import ContentLoader from "../../components/loader/ContentLoader"
import Navbar from "../../components/navbar/Navbar"
import { AppContext } from "../../context/AppContext"
import { fetchChatConversations } from "../../remote/http"
import { usernameToProfilePicUrl } from "../../remote/images"
import './styles/chat-conversations.scss'
import '../room/styles/webinar.scss'
import Icon from "../../components/icon/Icon"
import { socketJoinChatConversationsUpdates, socketUnregisterChatConversationsUpdates } from "../../remote/socket"

export default function ChatConversationsContainer() {
    const [appContext] = useContext(AppContext)

    const [page, setPage] = useState<number>(1)

    const [conversations, setConversations] = useState<Array<any> | null>(null)

    const fetchData = (page: number) => {
        setConversations(null)

        fetchChatConversations(appContext.token!, page)
            .then((res) => {
                setConversations(res.data)
            })
            .catch((err) => {
                alert(`Error: ${err}`)
                history.goBack()
            })
    }

    useEffect(() => {
        socketJoinChatConversationsUpdates(appContext.token!, (data: any) => {
            setConversations((oldConversations) => {
                if (oldConversations) {
                    const oldConversation: any = oldConversations.find((oldConversation: any) =>
                        oldConversation?.user?.username == data.username)
                    if (oldConversation) {
                        oldConversation.lastMessageTimestamp = data.lastMessageTimestamp
                    } else {
                        oldConversations.push(data)
                    }

                    return [...oldConversations]
                }

                return [data]
            })
        })

        return () => {
            socketUnregisterChatConversationsUpdates()
        }
    }, [])

    useEffect(() => fetchData(page), [page])

    return !conversations
        ? <ContentLoader />
        : <div className="chat-conversations room-workspace content">
            <Navbar
                title={`Chat Conversations`}
                onBack={() => history.goBack()}
                background={appContext.accentColor}
                hiddenIcons={["chat-conversations", "chat", "video-source", "help", "share-room-link", "whiteboard"]}
            />

            <div className="ag-btn-container">
                <span className="ag-btn-description">new chat</span>

                <button
                    onClick={() => history.push("/list-users")}
                    className="ag-btn videoControlBtn"
                >
                    <Icon type="plus" />
                </button>
            </div>

            <List
                className="content"
                itemLayout="horizontal"
                dataSource={conversations}
                renderItem={(conversation: any) => (
                    <List.Item
                        className="hoverable"
                        onClick={() => history.push(`/chat/${conversation.user.username}`)}>
                        <List.Item.Meta
                            avatar={<Avatar size={64} src={usernameToProfilePicUrl(conversation.user.username, false)} />}
                            title={<span style={{ color: "white" }}>{`${conversation.user.name} ${conversation.user.surname}`}</span>}
                            description={<span style={{ color: "#ababab" }}>Last message: {new Date(conversation.lastMessageTimestamp).toLocaleString()}</span>}
                        />
                    </List.Item>
                )}
            />
        </div>
}