import { Avatar, notification } from "antd"
import Button from "antd/lib/button"
import Input from "antd/lib/input"
import React, { useContext, useEffect, useRef, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { fetchListRoomMessages } from "../../remote/http"
import { usernameToProfilePicUrl } from "../../remote/images"
import {
	socketRoomJoin,
	socketSendChatMessage,
	socketUnregisterRoomJoin
} from "../../remote/socket"
import Icon from "../icon/Icon"
import ChatMessage from "./ChatMessage"
import "./styles/chat.scss"

interface Props {
	onClose?: () => void
	fullWidth?: boolean
	roomHash?: string
	username?: string
	chatBackground?: any
	moderators?: Array<string>
}

export default (props: Props) => {
	const [appContext] = useContext(AppContext)

	const [lastMessageTimestamp, setLastMessageTimestamp] = useState<Date>(
		new Date(8640000000000000)
	)
	const [isPaginationFinished, setIsPaginationFinished] = useState<boolean>(
		false
	)

	const [message, setMessage] = useState<string>("")
	const [allMessages, setAllMessages] = useState<any[]>([])

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const scrollRef = useRef<HTMLDivElement>(null)

	const sendMessageHandler = (msg = message) => {
		if (!appContext.token) {
			return
		}

		socketSendChatMessage(msg, appContext.token, props.roomHash, props.username)
		setMessage("")
	}

	const addMessage = (message: any) => {
		setAllMessages((messages) => [...messages, message])

		const scrollHeight = scrollRef.current?.scrollHeight || 0
		const height = scrollRef.current?.clientHeight || 0
		const maxScrollTop = scrollHeight - height

		scrollRef.current?.scrollTo(0, maxScrollTop > 0 ? maxScrollTop : 0)

		if (message.username !== appContext.username) {
			notification.open({
				message: message.username,
				description: message.message
			})
		}
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter" && !!message.length) {
			sendMessageHandler()
		}
	}

	useEffect(() => {
		if (!appContext.token) {
			return
		}

		setIsLoading(true)

		fetchListRoomMessages(
			appContext.token,
			lastMessageTimestamp,
			props.roomHash,
			props.username
		)
			.then((res) => {
				setIsPaginationFinished(res.isPaginationFinished)
				setAllMessages((oldMessages: Array<any>) => [
					...res.messages,
					...oldMessages
				])

				setIsLoading(false)
			})
			.catch(console.error)
	}, [lastMessageTimestamp])

	useEffect(() => {
		if (!appContext.token) {
			return
		}

		socketRoomJoin(appContext.token, props.roomHash, props.username, addMessage)

		return () => {
			if (appContext.token) {
				socketUnregisterRoomJoin(
					appContext.token,
					props.roomHash,
					props.username
				)
			}
		}
	}, [appContext.token])

	if (!appContext.token || !appContext.username) {
		return <h1>Error</h1>
	}

	const iAmModerator = (props?.moderators || []).includes(appContext.username)
	const isChatModerated = (props?.moderators || []).length > 0

	return (
		<div className={`chat ${props.fullWidth ? "fullwidth" : ""}`}>
			{!props.fullWidth && (
				<div className="chat-header">
					<button onClick={props.onClose}>
						<Icon type="close" />
					</button>
					Chat
				</div>
			)}
			<div
				className="chat-messages"
				style={
					props.chatBackground
						? {
								background: props.chatBackground.startsWith("http")
									? `url(${props.chatBackground})`
									: props.chatBackground
						  }
						: {}
				}
				ref={scrollRef}
			>
				{!isPaginationFinished && (
					<div style={{ width: "100%", textAlign: "center" }}>
						<Button
							style={{ justifyContent: "center" }}
							loading={isLoading}
							onClick={() => {
								const oldestMessage = allMessages[0]
								setLastMessageTimestamp(oldestMessage.timestamp)
							}}
						>
							{isLoading ? "Loading..." : "Load more"}
						</Button>
					</div>
				)}
				<div
					style={{
						marginLeft: props.fullWidth ? "var(--horizontal-margin)" : 0,
						marginRight: props.fullWidth ? "var(--horizontal-margin)" : 0
					}}
				>
					{(allMessages || []).map((message, i) => (
						<ChatMessage
							username={message.username}
							message={message.message}
							timestamp={message.timestamp}
							moderators={props.moderators}
							iAmModerator={!!iAmModerator}
							key={i}
						/>
					))}
				</div>
			</div>
			{isChatModerated && (
				<div className="chat-moderation-notice">
					{iAmModerator ? (
						<span>
							You are the moderator of this chat.
							<br />
							You'll get all the incoming messages.
							<br />
							Your messages will be visible to everyone.
						</span>
					) : (
						isChatModerated && <span>This chat is moderated</span>
					)}
				</div>
			)}
			<div
				className="chat-footer"
				style={!props.fullWidth ? { padding: "0 16px" } : {}}
			>
				<div className="avatar">
					<Avatar src={usernameToProfilePicUrl(appContext.username!, false)} />
				</div>
				<div className="comment-action">
					<Input
						placeholder="Say something..."
						className={"chat-input"}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						{...{ onKeyDown }}
					/>
				</div>
				<Button disabled={!message.length} onClick={() => sendMessageHandler()}>
					<Icon type="send" />
				</Button>
			</div>
		</div>
	)
}
